export default {
  created() {
    this.text = {
      workOrders: {
        mine: {
          title: 'Mijn Werkorders',
          description: 'Werkorders waar jij aan toegewezen bent',
        },
        all: {
          title: 'Alle Werkorders',
          description: 'Werkorders waar jij niet specifiek aan bent toegewezen',
        },
        statuses: {
          DRAFT: 'Concept',
          PLANNED: 'Gepland',
          ONGOING: 'Bezig',
          COMPLETED: 'Voltooid',
          CLOSED: 'Gesloten',
          CANCELLED: 'Geanuleerd',
        },
        statusesOngoing: {
          DEPARTED: {
            action: 'Vertrekken',
            title: 'Werkorder vertrekken',
            subtitle: 'Weet je zeker dat je wilt vertrekken?',
            description: '',
            equipmentTitle: 'Materiaal is onderweg naar:',
            equipmentActions: {
              history: [{
                key: 'amount',
                text: 'Meenemen',
              }],
              historyInverse: [],
              previous: {
                key: 'amount_loaded',
                text: 'Geladen',
              },
              next: {
                key: null,
                text: 'Uitladen',
              },
              previousInverse: {
                key: null,
                text: 'Vetrekken',
              },
              nextInverse: {
                key: 'amount',
                text: 'Ophalen',
              },
            },
          },
          ARRIVED: {
            action: 'Aangekomen',
            title: 'Werkorder aangekomen',
            subtitle: 'Weet je zeker dat je bent aangekomen?',
            description: '',
            equipmentTitle: {
              unload: 'Materiaal is aangekomen en je kunt uitladen bij:',
              load: 'Uitgeladen, wil je nog materiaal mee terugnemen?',
            },
            equipmentActions: {
              unload: {
                history: [{
                  key: 'amount',
                  text: 'Meenemen',
                }],
                buttonText: 'uitladen',
                previous: {
                  key: 'amount_loaded',
                  text: 'Geladen',
                },
                next: {
                  key: 'amount_unloaded',
                  text: 'Uitladen',
                },
              },
              load: {
                history: [{
                  key: 'amount',
                  text: 'Meenemen',
                }, {
                  key: 'amount_loaded',
                  text: 'Geladen',
                }],
                historyInverse: [{
                  key: 'empty',
                  text: null,
                }],
                buttonText: 'laden',
                future: ['amount_return_unloaded'],
                previous: {
                  key: 'amount_unloaded',
                  text: 'Uitgeladen',
                },
                next: {
                  key: 'amount_return_loaded',
                  text: 'Mee terug',
                },
                previousInverse: {
                  key: 'amount',
                  text: 'Ophalen',
                },
                nextInverse: {
                  key: 'amount_return_loaded',
                  text: 'Geladen',
                },
              },
            },
          },
          RETURNING: {
            action: 'Terugkeren',
            title: 'Terugkeren',
            subtitle: 'Weet je zeker dat je wilt terugkeren?',
            description: '',
            equipmentTitle: 'Onderweg terug naar',
            equipmentActions: {
              history: [{
                key: 'amount',
                text: 'Meenemen',
              }, {
                key: 'amount_loaded',
                text: 'Geladen',
              }, {
                key: 'amount_unloaded',
                text: 'Uitgeladen',
              }],
              historyInverse: [{
                key: 'empty',
                text: null,
              }, {
                key: 'amount',
                text: 'Ophalen',
              }],
              previous: {
                key: 'amount_return_loaded',
                text: 'Geladen',
              },
              next: {
                key: null,
                text: 'Uitladen',
              },
              previousInverse: {
                key: 'amount_return_loaded',
                text: 'Geladen',
              },
              nextInverse: {
                key: null,
                text: 'Uitladen',
              },
            },
          },
          // Not an actual status as status_ongoing is moved to NULL once a job is completed
          // We do however use this for text information of what comes after RETURNED
          COMPLETED: {
            action: 'Voltooien',
            title: 'Werkorder voltooien',
            subtitle: 'Weet je zeker dat de werkorder is voltooid?',
            description: '',
          },
        },
      },
    }
  },
}
