<template>
  <v-app id="app">
    <SWDialog />

    <v-navigation-drawer v-if="isTokenSet" temporary v-model="drawer" fixed app :rail="true" rail-width="400">
      <!-- Regular Navigation -->
      <v-list>
        <NavigationItem v-for="item in navigation.links" :key="item.text" :item="item" />
      </v-list>

      <!-- Helpful -->
      <v-list>
        <NavigationItem v-for="item in navigation.helpful" :key="item.text" :item="item" />
      </v-list>

      <!-- Log Out -->
      <v-list>
        <NavigationItem :item="{ icon: 'mdi-close', text: 'Uitloggen', method: logOut }" />
      </v-list>

      <!-- Calls -->
      <div class="calls">
        <NavigationItem v-for="item in navigation.calls" :key="item.phone" :item="item" />
      </div>
    </v-navigation-drawer>
    <v-toolbar v-if="isTokenSet" color="blue-darken-1" theme="dark" density="comfortable" elevation="4">
      <v-toolbar-title class="ml-0 pl-8">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        {{ pageTitle }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn flat >
        {{ user.name }}
      </v-btn>
      <v-col cols="2" v-if="user">
        <v-select v-model="branchSelected" variant="underlined" :items="user.branches" item-title="city" item-value="id" :return-object="false"> </v-select>
      </v-col>
    </v-toolbar>
    <div>
      <router-view v-if="online" />
      <template v-else>
        <v-alert :value="true" type="error">
          The device is offline.
        </v-alert>
      </template>
    </div>
    <notifications position="top center" />
  </v-app>
</template>

<script>
import NavigationItem from '@/components/NavigationItem'
import SWDialog from '@/components/UI/SWDialog'
import { useAuth } from '@eenvoud/eenvauth'
import * as Sentry from '@sentry/vue'
import useBranchSelector from './composable/useBranchSelector'

export default {
  components: {
    NavigationItem,
    SWDialog
  },
  data: () => ({
    drawer: false,
    online: true,
    auth: useAuth(),
    branchSelected: useBranchSelector().branchSelected,
    navigation: {
      links: [
        { icon: 'mdi-home', text: 'Werkorders', routeName: 'WorkOrders' },
      ],
      helpful: [
        { icon: 'mdi-help', text: 'Over deze app', routeName: 'About' },
      ],
      calls: [
        { icon: 'mdi-phone', iconColor: 'red', textColor: 'red', text: 'Alarmnummer hulpdiensten (urgent)', phone: 112, phoneFormatted: 112 },
        { icon: 'mdi-phone', iconColor: 'blue', textColor: 'black', text: 'Politie (niet urgent)', phone: '09008844', phoneFormatted: '0900-8844' },
        { icon: 'mdi-phone', iconColor: 'blue', textColor: 'black', text: 'Van Amerongen (directie/leiding)', phone: '0263516694', phoneFormatted: '026-3516694' },
      ],
    },
  }),
  computed: {
    pageTitle() {
      return this.$store.getters.appGetPageTitle
    },
    user() {
      return this.$store.getters.user
    },

    isTokenSet() {
      return !!(this.user && this.user.id)
    }
  },
  mounted() {
    window.addEventListener('offline', () => {
      this.online = false
    })
    window.addEventListener('online', () => {
      this.online = true
    })
  },
  methods: {
    async logOut() {
      Sentry.setUser(null)

      try {
        await this.auth.logout()
        this.$store.commit('app.setUser', {})
      } finally {
        this.$router.push({ name: 'Login' })
      }
    },
    setOnline(value) {
      this.online = value
    },
  },
  watch: {
    user: {
      handler: function (value) {
        if (value) {
          this.branchSelected = value.default_branch_id
        }
      },
      immediate: true,
      deep: true,
    }
  }
}
</script>
