<template>
  <v-list>
    <!-- Route change -->
    <v-list-item v-if="item.routeName" :to="{ name: item.routeName }" exact ripple="ripple" :title="item.title">
      <template v-slot:prepend>
        <v-icon :color="item.iconColor">
          {{ item.icon }}
        </v-icon>
      </template>
      
      <v-list-item-title>
        {{ item.text }}
      </v-list-item-title>
    </v-list-item>

    <!-- Method -->
    <v-list-item v-if="item.method" ripple="ripple" :title="item.title" @click="item.method()">
      <template v-slot:prepend>
        <v-icon :color="item.iconColor">
          {{ item.icon }}
        </v-icon>
      </template>
      
      <v-list-item-title>
        {{ item.text }}
      </v-list-item-title>
    </v-list-item>

    <!--Phone Call -->
    <v-list-item v-if="item.phone" :href="`tel:${item.phone}`" ripple="ripple" :title="item.text" class="phone">
      <template v-slot:prepend>
        <v-icon :color="item.iconColor">
          {{ item.icon }}
        </v-icon>
      </template>
      
      <v-list-item-title>
        <!-- <span class="phoneText">{{ item.text }}</span><br> -->
        <span class="phoneNumber" :style="`color: ${item.textColor}`">{{ item.phoneFormatted }}</span>
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.phone {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  .list__tile {
    .list__tile__title {
      height: auto;
      line-height: 20px;
    }
  }
  .phoneText {
    font-size: 13px;
    font-weight: normal;
  }
  .phoneNumber {
    font-size: 18px;
  }
}
</style>
