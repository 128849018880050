<template>
  <div>
    Updating hours...
  </div>
</template>

<script>
import { updateWorkOrderOngoingStatus } from '../../api/workOrders'

export default {
  name: 'WorkOrderReturned',

  props: {
    workOrder: {
      type: Object,
      required: true,
    },
  },

  created() {
    const employeeIds = this.workOrder.employees.map(({ id }) => id)

    updateWorkOrderOngoingStatus(this.workOrder.id, {
      people: employeeIds,
    }).then(({ data }) => {
      const { status, statusOngoing } = data

      this.$store.commit('workOrders.updateView', { key: 'status', value: status })
      this.$store.commit('workOrders.updateView', { key: 'status_ongoing', value: statusOngoing })
      this.$store.commit('workOrders.updateView', { key: 'last_time', value: (new Date()).toISOString() })

      this.gotoOverview()
    }).catch(() => {
      this.gotoOverview()
    })
  },

  methods: {
    gotoOverview() {
      this.$router.push({ name: 'WorkOrdersViewOverview', params: { id: this.workOrder.id } })
    },
  },
}
</script>
