<!-- <template>
  <div>
    <v-text-field
      ref="input"
      v-model="timeValue"
      :label="label"
      mask="##:##"
      return-masked-value
      prepend-icon="access_time"
      @click:prepend="openMenu"
      @input="save"
    />
    <div v-if="renderMenu">
      <v-menu
        ref="tp"
        v-model="menu"
        lazy
        :close-on-content-click="false"
        transition="scale-transition"
        offset-x
        full-width
        nudge-right="205px"
        nudge-top="100px"
        max-width="290px"
        min-width="290px"
        :return-value.sync="timeValue"
        :attach="this.$refs.input.$el"
      >
        <v-time-picker
          v-model="validatedTimeValue"
          :min="minTime"
          :max="maxTime"
          format="24hr"
          @change="$refs.tp.save(timeValue); save();"
        />
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Timepicker',
  props: ['model', 'label', 'minTime', 'maxTime'], // eslint-disable-line
  data() {
    return {
      validatedTimeValue: null,
      renderMenu: false,
      menu: false,
      loaded: false,
    }
  },
  computed: {
    timeValue: {
      get() {
        return this.validatedTimeValue
      },
      set(value) {
        if (this.checkTime(value)) {
          this.validatedTimeValue = value
        }
      },
    },
  },
  watch: {
    'model': function (value) {
      this.validatedTimeValue = this.model
    },
  },
  mounted() {
    this.validatedTimeValue = this.model
  },
  methods: {
    save() {
      this.$emit('update:model', this.timeValue)
    },
    showMenu() {
      this.$nextTick(() => {
        this.menu = true
      })
    },
    checkTime(timeString) {
      if (!timeString) {
        return false
      }

      const timeParts = timeString.split(':')

      // We care there's hours and minutes defined, the input mask (##:##) already ensures proper format HH:mm
      if (timeParts.length !== 2) {
        return false
      }

      // Make sure they're numbers
      const hours = parseInt(timeParts[0])
      const minutes = parseInt(timeParts[1])
      if (isNaN(hours) || isNaN(minutes) || parseInt(hours) > 24 || parseInt(minutes) > 60) {
        return false
      }

      return true
    },

    openMenu() {
      this.renderMenu = true
      this.showMenu()
    },
  },
}
</script> -->

<template>
  <div>
    <VueDatePicker
      :placeholder="placeholder"
      :name="name"
      :min-time="minTime"
      :max-time="maxTime"
      :disabled="disabled"
      :required="required"
      :readonly="readonly"
      :position="position"
      :model-value="model"
      @update:model-value="updateModel"
      :text-input="textInputOptions"
      time-picker
    > 
      <template #input-icon>
          <v-icon class="pl-2">
            mdi-clock-outline
          </v-icon>
        </template>
    </VueDatePicker>
  </div>
</template>

<script setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { defineComponent, onMounted, ref } from 'vue';
import emitArray from '@/utils/emit';

const emit = defineEmits(emitArray.value)

const removeUnexpectedCharacters = (value) => {
  return value.replaceAll(/[:.]/g, '')
}

const textInputOptions = {
  format: (time) => { 
    const clearTime = removeUnexpectedCharacters(time)

    const length = clearTime.length;
    const currentDate = new Date();

    if(length <= 2) {
      return currentDate.setHours(clearTime, 0)
    } else if (length > 2 && length == 3) {
      const hours = clearTime.substring(0, 1);
      const minutes = clearTime.substring(1, 3);
      return currentDate.setHours(hours, minutes)
    } else if (length > 2 && length == 4) {
      const hours = clearTime.substring(0, 2);
      const minutes = clearTime.substring(2, 4);
      return currentDate.setHours(hours, minutes)
    }
  }
};

defineComponent({
  VueDatePicker,
})

const model = ref()

const props = defineProps({
  placeholder: {
    type: String,
    required: false
  },
  name: {
    type: String,
    required: false
  },
  maxTime: {
    type: Object,
    default: null
  },
  minTime: {
    type: Object,
    default: null
  },
  autoApply: {
    type: Boolean,
    required: false,
    default: true
  },
  noToday: {
    type: Boolean,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false
  },
  readonly: {
    type: Boolean,
    required: false
  },
  required: {
    type: Boolean,
    required: false
  },
  position: {
    type: String,
    required: false,
    default: 'center'
  },
  modelValue: {
    required: false,
  }
})

onMounted(() => {
  model.value = props.modelValue
})

const updateModel = (newValue) => {
  model.value = newValue;
  emit('update-dates-and-times', { name: props.name, value: newValue})
  emit('update:value-datetime', formatTimeToString(newValue))
}

const formatTimeToString = (time) => {
  if (!time) {
    return ;
  }

  return `${(time.hours).toString().padStart(2, "0")}:${(time.minutes).toString().padStart(2, "0")}`
}

</script>
