<template>
  <TitleBar :workOrder="workOrder"/>
  <div class="wrapper">
    <!-- The list of employees and their hours -->
    <v-row wrap>
      <v-col sm="12">
        <Card
          v-for="(employee, index) in employeesSorted"
          :key="index"
          :random-color="true"
          :avatar-text="getAvatarText(employee.name)"
          :title="employee.name"
          :description="`Tijdregistratie op deze werkorder voor ${employee.name}`"
          :header-button="workOrder.status.name !== 'APPROVED' ? { action: () => triggerCreateEntryDialog(employee), icon: 'mdi-plus', color: 'orange darken-2', dark: true } : null"
          class="overflow-visible"
        >
          <template v-slot:content>
            <div v-if="employee.hours.length !== 0">
              <v-row
                v-for="(hourEntry, hindex) in employee.hours"
                :key="hindex"
                class="entry"
                wrap
                :class="!hourEntry.datetime_end ? 'ongoing' : ''"
              >
                <v-col sm="6" class="entry-info">
                  <p>
                    <span class="date">
                      {{ dayMonthFromDateTime(hourEntry.datetime_start) }}
                    </span>
                    <span class="hours">
                      {{ hourMinutesFromDateTime(hourEntry.datetime_start) }} -
                      <span v-if="hourEntry.datetime_end">
                        {{ hourMinutesFromDateTime(hourEntry.datetime_end) }}
                      </span>
                      <span v-else>Nu</span>
                    </span>
                    <br>
                    <span class="activity">
                      {{ hourEntry.activities || getActivityFromOngoingStatus(hourEntry.work_order_ongoing_status) }}
                    </span>
                  </p>
                </v-col>
                <v-col sm="5" class="time-amount">
                  <p v-if="hourEntry.datetime_end">
                    <span v-if="hourEntry.deleted_at" class="red--text">verwijderd</span>
                    <span v-else class="amount">{{ differenceFromDateTime(hourEntry.datetime_start, hourEntry.datetime_end) }}</span>
                    <br>
                    <span class="entry-type">{{ entryTypes(hourEntry) }}</span>
                  </p>
                </v-col>
                <v-col sm="1" class="edit-icon">
                  <v-btn v-if="workOrder.status.name !== 'APPROVED' && hourEntry.datetime_end && !hourEntry.deleted_at" size="small" icon @click="triggerEditEntryDialog(hourEntry)">
                    <v-icon color="grey darken-1">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn v-if="workOrder.status.name !== 'APPROVED' && hourEntry.deleted_at" size="small" icon @click="entryRestore(hourEntry)">
                    <v-icon color="grey darken-1">
                      mdi-backup-restore
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <p v-if="employee.hours.length === 0">
              <strong>Geen uren.</strong>
            </p>
          </template>
        </Card>
      </v-col>
    </v-row>

    <!-- Dialog for both creating an entry and editing an existing entry -->
    <Dialog
      v-if="entry"
      :visible.sync="entryDialog"
      @update:visible="entryDialog = $event"
      :title="entry.id ? 'Bewerk tijdsregistratie' : 'Maak tijdsregistratie aan'"
      class="overflow-visible"
      card-class="overflow-visible"
    >
      <template v-slot:content>
        <v-row>
          <v-col sm="6">
            <Datepicker
              label="Startdatum"
              :model-value="entry.dateStart"
              @update:value-datetime="entry.dateStart = $event"
            />
          </v-col>
          <v-col sm="6">
            <Timepicker
              label="Starttijd"
              :model-value="entry.timeStart"
              @update:value-datetime="entry.timeStart = $event"
            />
          </v-col>
          <v-col sm="6">
            <Datepicker
              label="Einddatum"
              :model-value="entry.dateEnd"
              @update:value-datetime="entry.dateEnd = $event"
            />
          </v-col>
          <v-col sm="6">
            <Timepicker
              label="Eindtijd"
              :model-value="entry.timeEnd"
              @update:value-datetime="entry.timeEnd = $event"
            />
          </v-col>
          <v-col sm="12">
            <v-text-field
              v-model="entry.activities"
              label="Werkzaamheden"
              placeholder="Beschrijf in een paar woorden de werkzaamheden"
              variant="underlined"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn flat color="blue darken-2" @click.stop="entryDialog = false">
          Annuleren
        </v-btn>
        <v-spacer />
        <v-progress-circular v-if="entryLoading" indeterminate color="primary" />
        <v-btn v-if="!entryLoading && entry.id" depressed outline color="red" @click="entryDelete">
          Verwijderen
        </v-btn>
        <v-btn v-if="!entryLoading" depressed color="blue darken-2" class="white--text" @click="entrySubmit">
          Opslaan
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { orderBy } from 'lodash-es'
import TitleBar from '@/components/WorkOrders/TitleBar'
import { fetchWorkOrderHours, insertWorkOrderHours, updateWorkOrderHours, deleteWorkOrderHours, restoreWorkOrderHours } from '@/api/workOrders'

import workOrdersMixin from '@/mixins/workOrders'
import textMixin from '@/mixins/text'
import { datetimeSplit } from '@/helpers'
import Say from '@/utils/Say'

import Datepicker from '@/components/UI/Datepicker'
import Timepicker from '@/components/UI/Timepicker'
import Card from '@/components/Card'
import Dialog from '@/components/Dialog'
import { getAvatarText, dayMonthFromDateTime, hourMinutesFromDateTime, differenceFromDateTime } from '@/utils/filters'

export default {
  components: {
    Card,
    Dialog,
    Datepicker,
    Timepicker,
    TitleBar,
  },
  mixins: [textMixin, workOrdersMixin],
  data() {
    return {
      employees: [],
      entry: {},
      entryDialog: false,
      entryLoading: false,
      getAvatarText,
      dayMonthFromDateTime,
      hourMinutesFromDateTime,
      differenceFromDateTime,
    }
  },
  computed: {
    workOrder() {
      return this.$store.getters.workOrdersView
    },
    employeesSorted() {
      return this.employees.map(employee => {
        employee.hours = orderBy(employee.hours, ['datetime_start'])
        return employee
      })
    },
  },
  mounted() {
    if (this.workOrder.id) {
      fetchWorkOrderHours(this.workOrder.id).then(res => {
        this.employees = res.data
      })
    }
  },
  methods: {
    entryTypes(entry) {
      const types = []
      if (Object.keys(this.text.workOrders.statusesOngoing).indexOf(entry.work_order_ongoing_status) === -1) {
        types.push('handmatig')
      } else {
        types.push('automatisch')
      }
      if (entry.updated_at !== null && entry.updated_at !== undefined) {
        types.push('aangepast')
      }
      return types.join(', ')
    },
    triggerCreateEntryDialog(employee) {
      this.entry = {
        employeeId: employee.id,
      }
      this.entryDialog = true
    },
    triggerEditEntryDialog(entry) {
      const start = datetimeSplit(entry.datetime_start)
      const end = datetimeSplit(entry.datetime_end)
      this.entry = {
        id: entry.id,
        employeeId: entry.employee_id,
        activities: entry.activities,
        dateStart: start.date,
        timeStart: start.time,
        dateEnd: end.date,
        timeEnd: end.time,
      }
      this.entryDialog = true
    },
    entrySubmit() {
      const required = ['dateStart', 'timeStart', 'dateEnd', 'timeEnd']

      // Basic validation, require all fields
      for (const field of required) {
        if (!this.entry[field]) {
          Say('error', 'Startdatum, starttijd, einddatum en eindtijd zijn verplicht.')
          return
        }
      }

      // Ensure the end is after the start
      const dateTimeStart = this.entry.dateStart + ' ' + this.entry.timeStart
      const dateTimeEnd = this.entry.dateEnd + ' ' + this.entry.timeEnd
      if (moment(dateTimeEnd).diff(moment(dateTimeStart)) < 0) {
        Say('error', 'Einde moet na de start zijn')
        return
      }

      // All good, decide what kind of submission to make
      const entry = {
        id: this.entry.id,
        employee_id: this.entry.employeeId,
        datetime_start: dateTimeStart,
        datetime_end: dateTimeEnd,
        activities: this.entry.activities,
      }

      const method = entry.id ? this.entryUpdate : this.entryInsert
      this.entryLoading = true
      method(entry)
        .then(() => {
          this.entryLoading = false
          this.entryDialog = false
        }).catch(err => {
          this.entryLoading = false
          Say('error', err.response.data)
        })
    },
    entryInsert(entry) {
      return insertWorkOrderHours(this.workOrder.id, entry)
        .then(res => {
          const entryId = res.data.id
          this.employees = this.employees.map(employee => {
            if (entry.employee_id === employee.id) {
              employee.hours.push(Object.assign(entry, {
                id: entryId,
              }))
            }
            return employee
          })
          Say('success', 'Opgeslagen')
        })
    },
    entryUpdate(entry) {
      return updateWorkOrderHours(this.workOrder.id, entry.id, entry)
        .then(res => {
          this.employees = this.employees.map(employee => {
            if (entry.employee_id === employee.id) {
              employee.hours = employee.hours.map(hourEntry => {
                if (hourEntry.id === entry.id) {
                  return Object.assign(res.data, {
                    datetime_start: res.data.datetime_start + ':00',
                    datetime_end: res.data.datetime_end + ':00',
                  })
                }
                return hourEntry
              })
            }
            return employee
          })
          Say('success', 'Opgeslagen')
        })
    },
    entryDelete() {
      this.entryLoading = true
      deleteWorkOrderHours(this.workOrder.id, this.entry.id)
        .then(res => {
          this.employees = this.employees.map(employee => {
            if (employee.id === this.entry.employeeId) {
              employee.hours = employee.hours.filter(hourEntry => {
                if (hourEntry.id === this.entry.id) {
                  hourEntry.deleted_at = moment().format('YYYY-MM-DD HH:mm:ss')
                }
                return hourEntry
              })
            }
            return employee
          })
          this.entryLoading = false
          this.entryDialog = false
        })
        .catch(err => {
          Say('error', err.response.data)
          this.entryLoading = false
        })
    },
    entryRestore(entry) {
      const confirmDialog = confirm('Are you sure?')
      if (confirmDialog) {
        restoreWorkOrderHours(this.workOrder.id, entry.id)
          .then(() => {
            this.employees = this.employees.map(employee => {
              if (employee.id === entry.employee_id) {
                employee.hours = employee.hours.map(hourEntry => {
                  if (hourEntry.id === entry.id) {
                    hourEntry.deleted_at = null
                  }
                  return hourEntry
                })
              }
              return employee
            })
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .card {
    margin-bottom: 10px;
    .card__text {
      padding: 0;
    }
  }
}
.entry {
  border-bottom: 1px solid #ddd;
  &.ongoing {
    opacity: .5;
  }
  p {
    margin-bottom: 0;
  }
  .date {
    font-size: 15px;
  }
  .hours {
    font-size: 17px;
    margin-left: 20px;
  }
  .activity {
    font-size: 17px;
  }
  .entry-info, .time-amount, .edit-icon {
    padding: 10px;
  }
  .time-amount {
    text-align: right;
    .amount {
      font-size: 15px;
      font-weight: bold;
    }
    .entry-type {
      font-size: 12px;
      color: #999;
    }
  }
}
</style>
