import Vuex from 'vuex'

import app from './app'
import workOrders from './workOrders'

export default new Vuex.Store({
  modules: {
    app,
    workOrders,
  },
})
