<template>
  <div>
    <template v-if="isWorkorderLoaded">
      <div class="submenu bg-blue-darken-2">
        <ul>
          <li v-for="(item, index) in submenu" :key="index">
            <router-link :to="item.route">
              {{ item.text }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="content">
        <router-view v-if="!loading" :work-order="workOrder" />
      </div>

      <WorkOrderFooter v-if="workOrder.id" :work-order="workOrder" />

      <!-- Next ongoing status Modal -->
      <Dialog v-if="nextOngoingStatusModal" :visible.sync="nextOngoingStatusModal" :title="nextOngoingStatus.title"
        @update:visible="nextOngoingStatusModal = $event">
        <template v-slot:content>
          <h2>{{ nextOngoingStatus.subtitle }}</h2>

          <p v-if="userEmployee && !userIsAssignedToWorkOrder">
            Je bent momenteel niet toegevoegd aan deze werkorder. Als je doorgaat dan word je toegevoegd aan de
            werkorder.
          </p>

          <p>Deze actie kan niet ongedaan worden gemaakt en zorgt er bovendien voor dat er tijdsregistraties voor het
            starten van de opdracht worden aangemaakt voor:</p>
          <ul class="employees-list">
            <li v-for="employee in workOrder.selectedEmployees" :key="employee.id">
              {{ employee.name }}
            </li>
            <li v-if="userEmployee && !userIsAssignedToWorkOrder">
              {{ userEmployee.name }}
            </li>
          </ul>
          <p v-if="departureWarning && workOrder.status.name === 'ONGOING' && workOrder.status_ongoing.name === 'STARTED'"
            class="red--text">
            <strong>Let op!</strong><br>
            Onderstaand materiaal is nog niet geladen:<br>
            {{ departureWarning }}
          </p>
        </template>
        <template v-slot:actions>
          <v-btn flat color="blue darken-2" @click.stop="nextOngoingStatusModal = false">
            Annuleren
          </v-btn>
          <v-spacer />
          <v-progress-circular v-if="nextOngoingStatusLoading" indeterminate color="primary" />
          <v-btn v-if="!nextOngoingStatusLoading" depressed color="blue darken-2" class="white--text"
            @click="triggerStatusAction()">
            {{ nextOngoingStatus.action }}
          </v-btn>
        </template>
      </Dialog>
    </template>
    <div v-else class="loading">
      Bezig met laden...
    </div>
  </div>
</template>

<script>
import { has } from 'lodash-es'

import { updateWorkOrderOngoingStatus, updateWorkOrder } from '@/api/workOrders'

import textMixin from '@/mixins/text'
import workOrdersMixin from '@/mixins/workOrders'

import Dialog from '@/components/Dialog'

import Say from '@/utils/Say'
import SayError from '@/utils/SayError'

import WorkOrderFooter from './WorkOrderFooter'

export { default as DropOffMaterials } from './DropOffMaterials'
export { default as PickUpMaterials } from './PickUpMaterials'
export { default as StartDriving } from './StartDriving'
export { default as WorkOrdersViewOverview } from './ViewOverview'
export { default as WorkOrdersViewNotes } from './ViewNotes'
export { default as WorkOrdersViewHours } from './ViewHours'
export { default as WorkOrdersViewHistory } from './ViewHistory'
export { default as WorkOrderReturned } from './WorkOrderReturned'

export default {
  components: {
    Dialog,
    WorkOrderFooter,
  },
  mixins: [textMixin, workOrdersMixin],
  data() {
    return {
      loading: true,
      nextOngoingStatusModal: false,
      nextOngoingStatusLoading: false,
      arrivedLoad: false,
    }
  },
  computed: {
    isWorkorderLoaded() {
      return this.workOrder.id === parseInt(this.$route.params.id, 10)
    },

    submenu() {
      const workOrderId = this.$route.params.id

      return [
        { text: 'Overzicht', route: { name: 'WorkOrdersViewOverview', params: { id: workOrderId } } },
        { text: 'Notities', route: { name: 'WorkOrdersViewNotes', params: { id: workOrderId } } },
        { text: 'Uren', route: { name: 'WorkOrdersViewHours', params: { id: workOrderId } } },
        { text: 'Geschiedenis', route: { name: 'WorkOrdersViewHistory', params: { id: workOrderId } } },
      ]
    },

    userIsAssignedToWorkOrder() {
      const employee = this.userEmployee

      if (!employee) {
        return false
      }

      return this.workOrder.selectedEmployees.some(
        ({ id }) => id === employee.id
      )
    },

    userEmployee() {
      const user = this.$auth.user()

      if (!user.employee) {
        return false
      }

      return user.employee
    },

    workOrder() {
      return this.$store.getters.workOrdersView
    },
    departureWarning() {
      return !this.workOrder.inverse
    },
    nextOngoingStatus() {
      // Is it ongoing?
      if (has(this.workOrder, 'status_ongoing.name')) {
        // If there is a next ongoing status
        if (this.workOrder.status_ongoing.meta.next !== null) {
          // Show next ongoing status
          return this.text.workOrders.statusesOngoing[this.workOrder.status_ongoing.meta.next]
        } else {
          // Show completed status
          return this.text.workOrders.statusesOngoing.COMPLETED
        }
      }
      return this.text.workOrders.statusesOngoing.STARTED
    },
  },

  watch: {
    '$route.params': {
      deep: true,
      immediate: true,
      handler(params) {
        if (this.isWorkorderLoaded) {
          this.loading = false
          return
        }

        this.$store.dispatch('workOrders.fetchSingle', params.id).then(() => {
          this.loading = false
        }).catch(err => {
          // show toast with error message
          SayError(err)
          this.loading = false
        })
      },
    },
  },

  mounted() {
    this.$store.commit('app.setPageTitle', 'Werkorder')
  },
  methods: {
    triggerStatusOngoingDialog() {
      this.nextOngoingStatusModal = true
    },
    async triggerStatusAction() {
      this.nextOngoingStatusLoading = true
      const equipment = this.workOrder.equipment.map(eq => {
        return {
          id: eq.id,
          amount: eq.amount || 0,
          amount_loaded: eq.amount_loaded,
          amount_unloaded: eq.amount_unloaded,
          amount_return_loaded: eq.amount_return_loaded,
          amount_return_unloaded: eq.amount_return_unloaded,
          flag_is_extra: eq.flag_is_extra,
        }
      })

      try {
        if (this.userEmployee && !this.userIsAssignedToWorkOrder) {
          await updateWorkOrder(this.workOrder.id, {
            employees: [
              ...this.workOrder.selectedEmployees.map(({ id }) => id),
              this.userEmployee.id,
            ],
          })
        }

        // TODO: I don't think equipment is handled at all in this call - DD
        const res = await updateWorkOrderOngoingStatus(this.workOrder.id, equipment)
        this.nextOngoingStatusLoading = false
        // Set the status
        const { status, statusOngoing } = res.data
        this.$store.commit('workOrders.updateView', {
          key: 'status',
          value: status,
        })
        // Set the status ongoing
        this.$store.commit('workOrders.updateView', {
          key: 'status_ongoing',
          value: statusOngoing,
        })
        // We expect the back-end to also set these amounts correctly
        if (statusOngoing && statusOngoing.name === 'RETURNED') {
          const equipmentUnload = this.workOrder.equipment.map(piece => {
            piece.amount_return_unloaded = piece.amount_return_loaded
            return piece
          })
          this.$store.commit('workOrders.setViewEquipment', equipmentUnload)
        }
        Say('success', 'De werkorder is bijgewerkt')
        this.nextOngoingStatusModal = false
      } catch (err) {
        this.nextOngoingStatusLoading = false
        Say('error', err.response.data)
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "./View.scss";
</style>
