<template>
  <v-list class="d-flex">
    <v-btn icon color="primary"  variant="outlined" @click="performAction">
      <v-icon size="large" color="primary">{{ icon }}</v-icon>
    </v-btn>
    <v-list-item>
      <v-list-item-title>
        {{ title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ subtitle }}
      </v-list-item-subtitle>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    action: {
      type: Function,
      required: false,
      default: undefined,
    },
    route: {
      type: Object,
      required: false,
      default: undefined,
    },
    href: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  methods: {
    performAction() {
      if (this.route) {
        this.$router.push(this.route)
      } else if (this.href && this.href.length > 0) {
        window.location.href = this.href
      } else if (this.action) {
        this.action()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  a {
    &:hover {
      background: none !important;
    }
  }
</style>
