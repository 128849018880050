export function workOrderTransformer(original) {
  const workOrder = { ...original }

  // Set "location from" text
  workOrder.locationFromText = 'Van Amerongen HQ'

  // Set "location to" text
  if (workOrder.location) {
    workOrder.locationToText = workOrder.location.location
  } else {
    workOrder.locationToText = 'UNKNOWN'
  }

  return workOrder
}
