import axios from 'axios'
import useBranchSelector from '@/composable/useBranchSelector'

const { branchSelected } = useBranchSelector()

export function fetchEmployees(filters) {
  let requestData = {
    params: {}
  }
  if (filters) {
    requestData.params = filters
  }
  requestData.params.branchId = branchSelected.value
  return axios.get('/employees', requestData)
}
