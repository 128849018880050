<template>
  <v-dialog v-model="show" :content-class="contentClass" :max-width="maxWidth" :scrollable="scrollable">
    <v-card :class="cardClass">
      <v-card-title>
        <ToolbarHeader
          :title="title"
          :close-dialog="closeDialog"
        />
      </v-card-title>
      <v-card-text>
        <slot name="content" />
      </v-card-text>
      <v-card-actions>
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ToolbarHeader from './ToolbarHeader'
export default {
  name: 'Dialog',
  components: {
    ToolbarHeader,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: '',
    },
    cardClass: {
      type: String,
      default: '',
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxWidth: {
      type: String,
      required: false,
      default: '80%',
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.closeDialog()
        }
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit('update:visible', false)
      this.$emit('close-dialog');
    },
  },
}
</script>

<style scoped lang="scss">
.card__title {
  padding: 0;
}
</style>
