<template>
  <div class="wrapper">
    <Month
      :year="year"
      :month="month"
      :week="week"
      :weekHours="weekHours"
    />
    <Week
      v-if="week"
      :year="year"
      :month="month"
      :week="week"
      @updateWeek="updateWeek"
    />
  </div>
</template>

<script>
import moment from 'moment'
import Month from './Month'
import Week from './Week'
export default {
  components: {
    Month,
    Week,
  },
  data() {
    return {
      weekHours: [],
    }
  },
  computed: {
    year() {
      return this.$route.params.year || moment().format('YYYY')
    },
    month() {
      return this.$route.params.month || moment().format('MM')
    },
    week() {
      return this.$route.params.week || moment().week()
    },
  },
  mounted() {
    this.$store.commit('app.setPageTitle', 'Uren (wekelijks)')
  },
  methods: {
    updateWeek(weekNr, weekHours) {
      this.week = weekNr
      this.weekHours = weekHours
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  // width: 100%;
  max-width: 100vw;
}
</style>
