import axios from 'axios'

export function fetchEquipment(data) {
  let requestData
  if (typeof data !== 'undefined') {
    requestData = {
      params: data,
    }
  }
  return axios.get('/equipment', requestData)
}

export function fetchJobEquipmentLocations(jobId, returnGrouped = 1, placedByWorkOrderId = null, collectedByWorkOrderId = null, workOrderId = null) {
  let requestData = {
    params: {
      rowsPerPage: 9999,
      page: 1,
      job_id: jobId,
      return_grouped: returnGrouped,
      work_order_id: workOrderId,
      placed_by_work_order_id: placedByWorkOrderId,
      collected_by_work_order_id: collectedByWorkOrderId,
    },
  };

  return axios.get(`/jobs/${jobId}/equipment-locations`, requestData)
}

export function getEquipmentItemsAtLocation(jobId, locationId, supplierId = null) {
  // nb jobId is not used in backend query
  let url = `/jobs/${jobId}/equipment-locations/at-location/${locationId}`
  if(supplierId !== null) {
    url = `${url}?supplier=${supplierId}`
  }
  return axios.get(url)
}

export function bulkUpdateEquipmentLocations(requestData) {
  if (typeof requestData !== 'undefined') {
    return axios.put(`/jobs/${requestData.job_id}/equipment-locations/bulk-update-grouped-with-quantities`, requestData)
  }
}

export function dropOffEquipment(jobId, requestData) {
  if (typeof requestData !== 'undefined') {
    return axios.post(`/jobs/${jobId}/equipment-locations`, requestData)
  }
}

export function dropOffEquipmentBulk(jobId, requestData) {
  if (typeof requestData !== 'undefined') {
    return axios.post(`/jobs/${jobId}/equipment-locations`, requestData)
  }
}
