<template>
  <div class="wrapper">
    <v-card flat>
      <v-card-text>
        <h2>Overzicht {{ monthName(month, year) }}</h2>
        <v-progress-circular v-if="loading" indeterminate color="primary" />
        <v-table v-if="!loading && !error" class="table">
          <thead>
            <tr>
              <th>week</th>
              <th />
              <th v-for="(day, index) in weekDays" :key="index">
                {{ ucfirst(day) }}
              </th>
              <th>Uren</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(week2, weekNr) in weeks" :key="weekNr" :class="weekNr === week ? 'current' : ''" @click="changeWeek(weekNr)">
              <td class="emphasize">
                {{ weekNr }}
              </td>
              <td class="selected-status">
                <span v-if="weekNr != week" class="select">selecteer</span>
                <span v-else>geselecteerd</span>
              </td>
              <td v-for="(day, index) in weekDays" :key="index" :class="isToday({ day: index+1, week: week2, weekNr }) ? 'current' : ''">
                <span v-if="week2.days[index+1]" :class="week2.days[index+1].worked ? 'worked' : ''">
                  {{ week2.days[index+1].monthDayNumber }}
                </span>
              </td>
              <td class="emphasize">
                {{ week2.worked ? toFixed(week2.worked) : week2.worked }}
              </td>
            </tr>
          </tbody>
        </v-table>
        <div v-if="error" class="error">
          {{ error }}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import { fetchMonth } from '@/api/hours'
import { toFixed, ucfirst } from '@/utils/filters'

export default {
  props: {
    year: {
      type: String,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
    week: {
      type: [Number, String],
      required: false,
      default: '',
    },
    weekHours: {
      type: Array,
      required: false,
      default: [],
    }
  },
  data() {
    return {
      weekDays: [1, 2, 3, 4, 5, 6, 7].map(day => moment().isoWeekday(day).format('dd')),
      loading: false,
      error: null,
      weeks: [],
      toFixed: toFixed,
      ucfirst,
    }
  },
  computed: {
    currentDate() {
      return {
        year: moment().format('YYYY'),
        week: moment().format('W'),
        day: moment().format('D'),
      }
    },
  },
  watch: {
    'weekHours': function (value) {
      let totalWork = 0
      for (let i = 0; i < 7; i++) {
        if (this.weeks[this.week].days[i+1]) {
          this.weeks[this.week].days[i+1].worked = value[i]
        }
        totalWork += value[i]
      }      
      this.weeks[this.week].worked = totalWork
    },
  },
  mounted() {
    this.loading = true
    fetchMonth(this.year, this.month)
      .then((res) => {
        this.loading = false
        this.weeks = res.data
      })
      .catch(err => {
        this.loading = false
        this.error = err.response.data
      })
  },
  methods: {
    monthName(month, year) {
      return moment(year + '-' + month + '-01').format('MMMM YYYY')
    },
    changeWeek(weekNr) {
      this.$router.push({
        name: 'Hours',
        params: {
          year: this.year,
          month: this.month,
          week: weekNr,
        },
      })
    },
    isToday({ week, day, weekNr }) {
      return (
        this.year === this.currentDate.year &&
        weekNr === this.currentDate.week &&
        !!week.days &&
        !!week.days[day] &&
        week.days[day].monthDayNumber === this.currentDate.day
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0;
  margin: 0;

  .error {
    padding: 15px;
    color: #fff;
  }
}
h2 {
  font-weight: normal;
}
.select {
  color: deeppink;
}
.table {
  thead {
    tr {
      th {
        padding: 6px 12px;
      }
    }
  }
  tbody {
    tr {
      &.current {
        background: #EBFAFF;
      }
      td {
        padding: 6px 0;
        height: 32px;
        text-align: center;
        &.emphasize {
          font-size: 16px;
        }
        &.current {
          span {
            background: #A8E9FF !important;
          }
        }
        &.selected-status {
          min-width: 80px;
          text-align: left;
        }
        span {
          display: inline-block;
          
          padding: 3px;
          text-align: center;
          border-radius: 20px;
          &.worked {
            background: #ddd;
          }
        }
      }
    }
  }
}
</style>
