import { ref } from "vue";

const emitArray = ref([
    'created',
    'update',
    'update:value-datetime',
    'update:model',
    'update:visible',
    'update:loading',
    'update:saving',
    'update:valid',
    'close',
    'update-dates-and-times',
    'change',
    'equipment:loaded',
    'show-template-ui',
    'hide-template-ui',
    'loading',
    'update:summary'
])

export default emitArray;