import { notify } from "@kyvg/vue3-notification";

// This is a wrapper on top of vue-notification - https://github.com/euvl/vue-notification/
// It was created in order to simplify showing notifications in the applications and make the calls less verbose
// It also handles notification messages coming as arrays of strings or an object with arrays of strings (as provided by default from Laravel)
// tl;dr Accepts: 'string1 string2 string3', ['string1', 'string2', 'string3'], {key1: ['string1'], key2: ['string2', 'string3']}
//       Returns: 'string1<br>string2<br>string3' inside a notification of type 'type'
export default function (type, data) {
  let message
  if (typeof data === 'string' || data instanceof String) {
    message = data
  } else if (data instanceof Array) {
    message = data.join('<br>')
  } else if (typeof data === 'object') {
    let msgArray = []
    for (let i = 0; i < Object.keys(data).length; i++) {
      const key = Object.keys(data)[i]
      msgArray = msgArray.concat(data[key])
    }
    if (msgArray.length > 0) {
      message = msgArray.join('<br>')
    } else if(data.message) {
      message = data.message
    }
  }
  notify({
    type: type,
    text: message,
  })
}
