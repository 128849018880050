<template>
  <div class="material-list mb-7">
    <v-form
      v-model="formValid"
      ref="formTable"
      validate-on="submit"
    >
      <v-table class="table v-table table-striped" density="compact">
        <thead>
        <tr>
          <td colspan="5" style="padding-top: 30px !important; padding-bottom: 10px !important; color: #1976d2;">
            {{ workOrder.location.location }} <span
            style="color: #666;">{{ workOrder.location.description ? ' - ' + workOrder.location.description : '' }}</span>
            <a :href="`https://maps.google.com/?q=${getGoogleMapsUrl(this.workOrder.location)}`" style="display: inline-block; margin-left: 10px; color: #666;"
               target="_blank">
              Open in Google Maps
              <v-icon style="font-size: 14px; margin-left: 5px;">mdi-open-in-new</v-icon>
            </a>
          </td>
        </tr>
        </thead>
        <thead>
        <tr>
          <th colspan="4" class="small">Voorgesteld materiaal</th>
          <th colspan="1" class="small">Aantal / Uniek kenmerk</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(equipmentItem, index) in equipmentRows" :key="index">
          <tr v-if="true || !equipmentItem.isPlaced" :class="equipmentItem.isPlaced ? ' greyed-out' : ''">
            <td class="text-left td-suggested-qty">
              {{ equipmentItem.quantity  }}
            </td>
            <td class="text-left td-placed-qty">
              {{ getAmountAlreadyPlaced(equipmentItem)  }}
              <template v-if="workOrder.is_for_equipment_movement && ( workOrder.category === 'Materiaal ophalen bij vestiging' || workOrder.category === 'Materiaal ophalen bij verhuur leverancier' )">
                opgehaald
              </template>
              <template v-else="">
                geplaatst
              </template>
            </td>
            <td class="td-equipment-img">
              <template v-if="equipmentItem.image_url">
                <img :src="equipmentItem.image_url" class="equipment-img" />
              </template>
              <template v-else>
                <img v-if="equipmentItem.image" :src="imageUrl(equipmentItem.image, '/storage/equipment/')" class="equipment-img" />
              </template>
            </td>
            <td class="no-wrap">
              <span :class="equipmentItem.no_loc ? '' : 'compact'">
                {{ equipmentItem.name }}
                <strong class="" v-if="equipmentItem.supplier_id"> | {{rentalSupplier(equipmentItem.supplier_id)?.name}}</strong>
                {{ equipmentItem.note ? ' | ' : '' }} {{equipmentItem.note}}
              </span>
            </td>
            <td class="quantity no-wrap" :title="equipmentItem.identifier">
              <template v-if="equipmentItem.is_unique && !equipmentItem.is_composite && !equipmentItem.isPlaced">
                <v-autocomplete
                  v-model="formValues[getModelIdentifier(null, equipmentItem, 'qty')]"
                  :items="getEquipmentItemsFromEquipmentId(equipmentItem.equipment_id).filter((item) => {
                    return item.id === formValues[getModelIdentifier(null, equipmentItem, 'qty')] || !uniqueItemsUsed.includes(item.id)
                  })"
                  class="autocomplete"
                  density="compact"
                  flat
                  item-title="label"
                  item-value="id"
                  label="Kies kenmerk"
                  :hide-details="true"
                  single-line
                  variant="underlined"
                  clearable
                ></v-autocomplete>
              </template>
              <template v-else-if="equipmentItem.is_unique && equipmentItem.is_composite && !equipmentItem.isPlaced">
                <template v-if="Object.keys(equipmentItem.equipment.hasUnique).length">
                  <template v-for="(uniqueEquipmentItem, uniqueItemEquipmentId) of equipmentItem.equipment.hasUnique">
                    <v-autocomplete
                      v-model="formValues[getModelIdentifier({is_composite_unique: true, equipment_id: uniqueItemEquipmentId, item: uniqueEquipmentItem}, equipmentItem, 'qty')]"
                      :items="getEquipmentItemsFromEquipmentId(uniqueItemEquipmentId).filter((item) => {
                        return item.id === formValues[getModelIdentifier({is_composite_unique: true, equipment_id: uniqueItemEquipmentId, item: uniqueEquipmentItem}, equipmentItem, 'qty')]
                              || ((identifierSuppliers[equipmentItem.identifier] === undefined || identifierSuppliers[equipmentItem.identifier] === item.rental_supplier_id) && !uniqueItemsUsed.includes(item.id))
                      })"
                      class="autocomplete"
                      density="compact"
                      flat
                      item-title="label"
                      item-value="id"
                      :label="`Kies '${uniqueEquipmentItem.name}' kenmerk`"
                      single-line
                      :hide-details="Object.keys(equipmentItem.equipment.hasUnique).length === 1"
                      variant="underlined"
                      :prefix="`${uniqueEquipmentItem.name} : `"
                      :rules="[formRules.composite(equipmentItem.identifier, equipmentItem.equipment.hasUnique)]"
                      clearable
                      @update:model-value="$refs.formTable.validate()"
                    ></v-autocomplete>
                  </template>
                </template>
                <template v-else>
                  Geen materiaal beschikbaar
                </template>
              </template>
              <template v-else-if="!equipmentItem.is_unique && !equipmentItem.isPlaced">
                <template v-if="getAmountAvailableLocation(equipmentItem).length">
                  <div
                    v-for="(vaEquipment, vaEquipmentIndex) in getAmountAvailableLocation(equipmentItem)"
                    :key="vaEquipmentIndex"
                    class="supplier-quantity"
                    :class="vaEquipment.rental_supplier_id && vaEquipment.rental_supplier_id === equipmentItem.supplier_id ? 'suggested' : ''"
                  >
                    <v-text-field
                      v-model="formValues[getModelIdentifier(vaEquipment, equipmentItem, 'qty')]"
                      class="quantity"
                      density="compact"
                      type="number"
                      variant="underlined"
                      :max="vaEquipment.rental_supplier_id ? vaEquipment.count : false"
                      :min="0"
                      :rules="[formRules.quantity(getModelIdentifier(vaEquipment, equipmentItem, 'qty'), vaEquipment.rental_supplier_id ? vaEquipment.count : false)]"
                      hide-details
                    />
                    <span v-if="vaEquipment.rental_supplier_id">(max. {{vaEquipment.count}})</span>
                    <span v-if="vaEquipment.rental_supplier_id">{{rentalSupplier(vaEquipment.rental_supplier_id)?.name}}</span>
                  </div>
                </template>
                <template v-else>
                  Er is geen materiaal beschikbaar
                </template>
              </template>
            </td>
          </tr>
        </template>
        <tr>
          <td colspan="5">
            <v-btn
              prepend-icon="mdi-plus"
              @click="addDialogVisible"
              class="add-extra-equipment-btn"
            >
              {{equipment.length ? 'Extra materiaal toevoegen' : 'Materiaal toevoegen'}}
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-table>
    </v-form>
  </div>
  <div class="mt-12 mb-12">
    <v-btn color="primary" style="width: 120px;" @click="submitMaterialList">
      <template v-if="!formSubmitted">
        <template v-if="workOrder.is_for_equipment_movement && ( workOrder.category === 'Materiaal ophalen bij vestiging' || workOrder.category === 'Materiaal ophalen bij verhuur leverancier' )">
          Ophalen
        </template>
        <template v-else="">
          Plaatsen
        </template>
      </template>
      <template v-else>
        <v-progress-circular
          indeterminate
          color="white"
          width="2"
          size="24"
        ></v-progress-circular>
      </template>
    </v-btn>
    <v-btn :to="{ name: 'WorkOrdersView', params: { id: workOrder.id } }" class="ml-12" flat>
      Annuleren
    </v-btn>
  </div>
</template>
<script>
import {dropOffEquipment, dropOffEquipmentBulk} from '@/api/equipment'
import {cloneDeep} from "lodash-es";
import SayError from '@/utils/SayError'

export default {
  props: {
    workOrder: {
      type: Object,
      required: true,
    },
    equipment: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    rentalSuppliers: {
      type: Array,
      required: true,
      default: () => [],
    },
    equipmentAtLocation: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    equipmentAtVaLocation: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    additionEquipment: {
      type: Array,
      required: true,
      default: () => [],
    },
    moveOnlyRental: {
      type: Boolean,
      required: true
    },
    formLocationId: {
      required: true
    },
    toLocationId: {
      required: true
    }
  },
  emits:['addAdditional'],
  data() {
    return {
      formSubmitted: false,
      formValid: false,
      formRules: {
        quantity: (id, checkMax) => {
          const value = this.formValues[id]

          // TODO: In case we ever want to force a value for this
          // // Also what we need to do is check for the "No Value Additional"
          // let key = id.split('_')
          // key.pop()
          // key = key.join('_')
          // if(this.noValueAdditionalRows.includes(key)) return `You must enter a value`

          // If the field is not set then we are fine
          if(!value)            return true
          if(value < 0)         return 'Must be a positive number'
          if(value > 250)       return 'Max of 250 allowed per equipment'
          if(!checkMax)         return true
          if(value > checkMax)  return `You can move a maximum of ${checkMax}`
          return true
        },
        composite: (id, itemSet) => {
          if(Object.keys(itemSet).length === 1) return true
          const dataEntries = Object.entries(this.formValues).filter(entry => entry[0].includes(`_${id}_`) && entry[1])
          if(dataEntries.length === 0) return true
          return (Object.keys(itemSet).length === dataEntries.length) ? true : 'Select all unique options for item'
        }
      },
      formValues: {},
      additionalRows: [],
    }
  },
  computed: {
    uniqueItemsUsed() {
      let data = []
      for (const [key, value] of Object.entries(this.formValues)) {
        if(key.includes('unique') && value) {
          data.push(value)
        }
      }
      return data
    },
    noValueAdditionalRows() {
      let rows = []
      let values = {}
      for (const [index, value] of Object.entries(this.formValues)) {
        let key = index.split('_')
        key.pop()
        key = key.join('_')

        if(!values[key]) {
          values[key] = []
        }
        if(value && value > 0) {
          values[key].push(value)
        }
      }
      for (const i in this.additionalRows) {
        if(!values[this.additionalRows[i]] || values[this.additionalRows[i]].length === 0) {
          rows.push(this.additionalRows[i])
        }
      }
      return rows
    },
    itemsToPlace() {
      let data = {
        composite: {},
        bulk: {},
        unique: [],
        uniqueWithSupplier: {},
      }
      for (const [key, value] of Object.entries(this.formValues)) {
        if(!value) continue

        const splitKey = key.split('_')

        if(splitKey[0] === 'bulk') {
          if(!data['bulk'][splitKey[1]]) {
            data['bulk'][splitKey[1]] = {}
          }
          if(!data['bulk'][splitKey[1]][splitKey[3]]) {
            data['bulk'][splitKey[1]][splitKey[3]] = 0
          }
          data['bulk'][splitKey[1]][splitKey[3]] = data['bulk'][splitKey[1]][splitKey[3]] + parseInt(value)
        } else if(splitKey[0] === 'unique') {
          const equipment = this.getEquipmentItemsFromEquipmentId(splitKey[1], value)
          data['uniqueWithSupplier'][value] = equipment.rental_supplier_id
          data['unique'].push(value)
        } else if(splitKey[0] === 'composite') {
          if(splitKey[3] === 'unique') {
            const splitId = splitKey[1].split('-')

            if(!data['composite'][splitId[0]]) {
              data['composite'][splitId[0]] = {
                is_bulk : false,
                items: {},
              }
            }
            if(!data['composite'][splitId[0]]['items'][splitKey[2]]) {
              data['composite'][splitId[0]]['items'][splitKey[2]] = {}
            }
            data['composite'][splitId[0]]['items'][splitKey[2]][splitId[1]] = parseInt(value)

          } else {
            if(!data['composite'][splitKey[1]]) {
              data['composite'][splitKey[1]] = {
                is_bulk : true,
                qty: {}
              }
            }
            if(!data['composite'][splitKey[1]]['qty'][splitKey[3]]) {
              data['composite'][splitKey[1]]['qty'][splitKey[3]] = 0
            }
            data['composite'][splitKey[1]]['qty'][splitKey[3]] = data['composite'][splitKey[1]]['qty'][splitKey[3]] + parseInt(value)
          }
        }
      }
      return data
    },
    equipmentRows() {
      let data =  cloneDeep(this.equipment)
      // At the end of the equipment rows we need to add any additional rows
      for (const index in this.additionEquipment) {
        let additionalRow = cloneDeep(this.additionEquipment[index])
        additionalRow.identifier = `additional-${index}`

        additionalRow.is_unique = false
        if(additionalRow.unique_feature || additionalRow.hasUnique ) {
          const equipmentAtVaLocation = this.equipmentAtVaLocation.filter(item => item.id == additionalRow.id)[0]
          additionalRow.is_unique = true
          additionalRow.equipment = equipmentAtVaLocation

          if(additionalRow.is_single) {
            if(!this.uniqueItemsUsed.includes(additionalRow.id)) {
              // We also want to pre-set the item
              this.formValues[this.getModelIdentifier(additionalRow, additionalRow, 'qty')] = additionalRow.id
            }
          } else {
            additionalRow.is_composite = true
          }
        } else {
          const equipmentAtVaLocation = this.equipmentAtVaLocation.filter(item => item.equipment_id == additionalRow.equipment_id)[0]
          additionalRow.is_unique = false
          additionalRow.equipment = equipmentAtVaLocation
        }
        if(additionalRow.composite_id) {
          additionalRow.is_composite = true
        }
        additionalRow.isAdditionalRow = true

        /**
         * This section is to identify the additional rows added so that there can be some special validation
         */
        if(additionalRow.is_unique && additionalRow.is_composite) {
          for (const [uniqueItemEquipmentId, uniqueEquipmentItem] of Object.entries(additionalRow.equipment.hasUnique)) {
            const ident = this.getModelIdentifier({is_composite_unique: true, equipment_id: uniqueItemEquipmentId, item: uniqueEquipmentItem}, additionalRow, 'qty', false)
            if(!this.additionalRows.includes(ident)) {
              this.additionalRows.push(ident)
            }
          }
        } else {
          const ident = this.getModelIdentifier(null, additionalRow, 'qty', false)
          if(!this.additionalRows.includes(ident)) {
            this.additionalRows.push(ident)
          }
        }
        data.push(additionalRow)
      }
      return data
    },
    identifierSuppliers() {
      let data = {}
      for (const [key, value] of Object.entries(this.formValues)) {
        const splitKey = key.split('_')
        if(data[splitKey[2]] === undefined && value) {
          const equipmentId = splitKey[1].includes('-') ? splitKey[1].split('-')[1] : splitKey[1]
          const equipment = this.getEquipmentItemsFromEquipmentId(equipmentId, value)
          data[splitKey[2]] = equipment?.rental_supplier_id
        }
      }
      return data
    },
  },
  methods: {
    imageUrl(url, path = '') {
      if (!url) {
        return ''
      }
      return `${import.meta.env.VITE_APP_BASE_API_URL}${path}${url}`
    },
    addDialogVisible() {
      this.$emit('addAdditional')
    },
    rentalSupplier(id) {
      return this.rentalSuppliers.filter(item => item.id == id)[0] ?? {};
    },
    getModelIdentifier(vaEquipment, equipmentItem, field, attachRental=true) {
      let itemType = 'bulk'
      let rentalSupplier = vaEquipment?.rental_supplier_id  ?? 'va'
      let equipment_id = equipmentItem.equipment_id

      if(vaEquipment?.is_composite_unique) {
        itemType = `composite_${equipmentItem.equipment_id}-${vaEquipment.equipment_id}`
        equipment_id = null
        rentalSupplier = 'unique'
      } else if(equipmentItem.is_unique) {
        itemType = 'unique'
        rentalSupplier = 'unique'
      } else if(equipmentItem.is_composite) {
        itemType = `composite_${equipmentItem.equipment_id}`
        equipment_id = null
      }

      let identifierArray = []
      identifierArray.push(itemType)
      if(equipment_id) identifierArray.push(equipment_id)
      identifierArray.push(equipmentItem.identifier)
      if(attachRental) {
        identifierArray.push(rentalSupplier)
      }
      return identifierArray.join('_')
    },
    setEquipmentQty(event, vaEquipment, equipmentItem) {
      this.formValues[this.getModelIdentifier(vaEquipment, equipmentItem, 'qty')] = event.target.value
    },
    getEquipmentItemsFromEquipmentId(equipmentId, equipmentItemId=false) {
      let data =  this.equipmentAtVaLocation.filter(item => item.equipment_id == equipmentId)

      for (const index in data) {
        const rentalSupplier = this.rentalSupplier(data[index].rental_supplier_id)?.name
        data[index].label = rentalSupplier ? `${rentalSupplier} | ${data[index].unique_feature}` : data[index].unique_feature
      }

      if(equipmentItemId) {
        return data.filter(item => item.id == equipmentItemId)[0];
      }
      return data.sort((a, b) => a.rental_supplier_id - b.rental_supplier_id)
    },

    getAmountAvailableLocation(equipmentItem) {
      let data = this.getAmountAvailableAtVaLocation(equipmentItem);
      if(this.moveOnlyRental) {
        data = data.filter(item => item.rental_supplier_id )
      }
      return data
    },
    getAmountAvailableAtVaLocation(equipmentItem) {
      const equipmentItemData = equipmentItem.equipment
      if(equipmentItem.is_composite) {
        // Is this is a composite item then we will need to manually count the items available for each rental supplier
        let data = []
        if(!equipmentItemData) {
          return data
        }

        for (const supplier in equipmentItemData.supplierCounts) {
          if(equipmentItemData.supplierCounts[supplier] === 0) {
            continue;
          }
          data.push({
            rental_supplier_id: supplier === 'va' ? null : supplier,
            count: equipmentItemData.supplierCounts[supplier]
          })
        }
        data = data.sort((a, b) => a.rental_supplier_id - b.rental_supplier_id)

        if(data.length === 0) {
          if(!equipmentItem.is_unique && !equipmentItem.rental_supplier_id) {
            return [{
              rental_supplier_id: null,
              count: 0
            }]
          }
        }


        const vsItem = data.filter(item => !item.rental_supplier_id)
        if(vsItem.length === 0) {
          let clone = cloneDeep(data[0])
          clone.rental_supplier_id = null
          clone.count = 0
          data.unshift(clone)
        }

        return data
      }
      const data =  this.equipmentAtVaLocation
        .filter(item => item.equipment_id == equipmentItem.equipment_id)
        .sort((a, b) => a.rental_supplier_id - b.rental_supplier_id)

      if(data.length === 0) {
        if(!equipmentItem.is_unique && !equipmentItem.rental_supplier_id) {
          return [{
            rental_supplier_id: null,
            count: 0
          }]
        }
        return []
      }
      const vsItem = data.filter(item => !item.rental_supplier_id)
      if(vsItem.length === 0) {
        let clone = cloneDeep(data[0])
        clone.rental_supplier_id = null
        clone.count = 0
        data.unshift(clone)
      }

      return data
    },
    getLocationAddress(location) {
      return location ? location.location : '';
    },
    getGoogleMapsUrl(location) {
      if (location.latitude && location.longitude) {
        return `${location.latitude},${location.longitude}`;
      }
      return encodeURIComponent(this.getLocationAddress(location));
    },
    getAmountAlreadyPlaced(equipmentItem, render=false) {
      if(!render) return equipmentItem.isPlacedCount ?? 0

      const quantity = this.getAmountSuggested(equipmentItem)

      return `${quantity} (${equipmentItem.isPlacedCount} ${workOrder.is_for_equipment_movement && ( workOrder.category === 'Materiaal ophalen bij vestiging' || workOrder.category === 'Materiaal ophalen bij verhuur leverancier' ) ? 'opgehaald' : 'geplaatst'})`
    },
    getAmountSuggested(equipmentItem) {

      return equipmentItem.totalSuggested
      const data = this.equipment.filter(item => equipmentItem.equipment_id == item.equipment_id)
      let quantity = 0;
      for (const item of data) {
        quantity = quantity + item.quantity
      }
      return quantity
    },
    async submitMaterialList() {
        if(this.formSubmitted) return

        this.formSubmitted = true

        await this.$refs.formTable.validate()

        if(!this.formValid) {

          const extraError = this.$refs.formTable?.errors[0]?.errorMessages[0] ?? null
          let errorMsg = 'Er zijn fouten gevonden in het formulier'
          if(extraError) {
            errorMsg = `${errorMsg} | ${extraError}`
          }
          alert(errorMsg)
          this.formSubmitted = false
          return false
        }


        let confirmDialogShown = false
      // check if all amounts are set
      const confirmDialogText = 'Niet alle aantallen zijn ingevuld. Weet je zeker dat je door wilt gaan?'

      // Check if there are "additional material" rows with no data added
      if(this.noValueAdditionalRows.length > 0) {
        const confirmDialog = confirm(confirmDialogText)
        if (!confirmDialog) {
          this.formSubmitted = false
          return
        }
        confirmDialogShown = true
      }

      // We need to build the set of submits

      // convert the items into a state that can be sent to the API

      let payloads = []

      // The easy one is the bulk items
      for (const equipmentId in this.itemsToPlace['bulk']) {
        for (const rentalSupplierId in this.itemsToPlace['bulk'][equipmentId]) {
          payloads.push({
            amount: this.itemsToPlace['bulk'][equipmentId][rentalSupplierId],
            compositeUnique: {},
            equipmentId: equipmentId,
            equipment_id: equipmentId,
            equipment_item_id: null,
            from_location_id: this.formLocationId,
            job_id: this.workOrder.job_id,
            location_change_type: "place",
            location_id: this.toLocationId,
            supplier_id: rentalSupplierId === 'va' ? null : rentalSupplierId,
            work_order_id: this.workOrder.id,
          })
        }
      }

      // Then composite items
      for (const equipmentId in this.itemsToPlace['composite']) {
        // If the composite is a bulk
        if(this.itemsToPlace['composite'][equipmentId].is_bulk) {
          for (const rentalSupplierId in this.itemsToPlace['composite'][equipmentId]['qty']) {
            payloads.push({
              amount: this.itemsToPlace['composite'][equipmentId]['qty'][rentalSupplierId],
              compositeUnique: {},
              equipmentId: equipmentId,
              equipment_id: equipmentId,
              equipment_item_id: null,
              from_location_id: this.formLocationId,
              job_id: this.workOrder.job_id,
              location_change_type: "place",
              location_id: this.toLocationId,
              supplier_id: rentalSupplierId === 'va' ? null : rentalSupplierId,
              work_order_id: this.workOrder.id,
              is_composite: true
            })
          }
        } else {
          for (const itemKey in this.itemsToPlace['composite'][equipmentId]['items']) {
            payloads.push({
                amount: 1,
                compositeUnique: this.itemsToPlace['composite'][equipmentId]['items'][itemKey],
                equipmentId: equipmentId,
                equipment_id: equipmentId,
                equipment_item_id: null,
                from_location_id: this.formLocationId,
                job_id: this.workOrder.job_id,
                location_change_type: "place",
                location_id: this.toLocationId,
                supplier_id: this.identifierSuppliers[itemKey] ?? null,
                work_order_id: this.workOrder.id,
                is_composite: true
              })
          }
        }
      }
      for (const index in this.itemsToPlace['uniqueWithSupplier']) {
          payloads.push({
            amount: 1,
            compositeUnique: {},
            equipmentId: index,
            equipment_id: null,
            equipment_item_id: index,
            from_location_id: this.formLocationId,
            job_id: this.workOrder.job_id,
            location_change_type: "place",
            location_id: this.toLocationId,
            supplier_id: this.itemsToPlace['uniqueWithSupplier'][index],
            work_order_id: this.workOrder.id,
          })
      }

      if(!confirmDialogShown) {
        // if all amounts are not set (null), payloads will be empty
        if (payloads.length === 0) {
          const confirmDialog = confirm(confirmDialogText)
          if (!confirmDialog) {
            this.formSubmitted = false
            return
          }
        }
      }
      if(!confirmDialogShown) {
        // check if one of the amounts of the paylods is zero
        if (payloads.some(payload => payload.amount === 0)) {
          const confirmDialog = confirm(confirmDialogText)
          if (!confirmDialog) {
            this.formSubmitted = false
            return
          }
        }
      }

      try {
        await dropOffEquipmentBulk(this.workOrder.job_id, payloads)
        // redirect to work order view
        this.$router.push({name: 'WorkOrdersView', params: {id: this.workOrder.id}});
      } catch (err) {
        SayError(err)
      }

      this.formSubmitted = false
    },
    updateEquipmentItem(equipmentItem) {
      if (equipmentItem.equipment_item_id > 0) {
        equipmentItem.amount = 1
      }
      // check unique items
      this.equipment.forEach(item => {
        if (item.equipment_id === equipmentItem.equipment_id && item.equipment_item_id !== equipmentItem.equipment_item_id) {
          // remove unique feature from other lists
          item.items = item.items.filter(uniqueItem => uniqueItem.equipment_item_id !== equipmentItem.equipment_item_id)
        }
      })
    },
  },
};

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

</script>

<style lang="scss">
.greyed-out {
  color: #AAA;
}

.greyed-out img {
  opacity: 0.5;
}

.no-wrap {
  white-space: nowrap;
}
.material-list {
  .table-striped {
    table {
      tbody, thead {
        tr {
          td, th {
            text-align: left;
            vertical-align: middle;
            padding:5px 5px !important;
            &.small {
              width: 20px;
            }
            &.td-suggested-qty {
              width: 60px;
            }
            &.td-placed-qty {
              width: 90px;
              white-space: nowrap;
            }
            &.td-equipment-img {
              width: 70px;
              .equipment-img {
                max-width: 40px;
                max-height: 30px;
                display: block;
                margin: 0 auto;
              }
            }
            &.quantity {
              width: 40%;
              > div.supplier-quantity {
                display: flex;
                align-items: center;
                gap: 10px;

                &.suggested {
                  font-weight: bold;
                }
              }
              > div.supplier-quantity + div.supplier-quantity {
                margin-top:5px;
              }
            }
          }
        }
      }
    }
  }
}
.autocomplete {
  span, input {
    float: left;
    font-size: 14px;
  }

  label {
    font-size: 14px;
  }

  .compact {
    margin-top: 14px;
    padding-right: 10px;
  }

  .v-field__field {
    margin-top: 5px;
  }
}
</style>
