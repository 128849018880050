<template>
  <v-toolbar color="primary">
    <v-toolbar-title class="white--text">
      {{ title }}
    </v-toolbar-title>
    <v-spacer />
    <v-btn icon class="white--text" @click="closeDialog()">
      <v-icon color="white">mdi-close</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: 'ToolbarHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    closeDialog: {
      type: Function,
      required: true,
    },
  },
}
</script>
