import moment from 'moment'
import 'moment/dist/locale/nl'
import axios from 'axios'

moment.locale('nl')

export const hourFromDateTime = function (datetimeIso) {
  const day = moment(datetimeIso)
  return day.format('HH:mm')
}

export const relativeDateFromDateTime = function (datetimeIso, fromTime) {
  if (fromTime) {
    return moment(datetimeIso).fromNow()
  }
  const day = moment(datetimeIso).startOf('day')
  const today = moment().startOf('day')
  const difference = today.diff(day, 'days')
  /* eslint-disable no-unreachable */
  switch (difference) {
    case -1:
      return 'morgen'
      break
    case 0:
      return 'vandaag'
      break
    case 1:
      return 'gisteren'
      break
    default:
      return day.fromNow()
      break
  }
  /* eslint-enable no-unreachable */
}

export const niceDateFromDateTime = function (datetimeIso) {
  return moment(datetimeIso).format('D MMMM YYYY')
}

export const dateOnOm = function (datetimeIso) {
  const datetime = moment(datetimeIso)
  return '<strong>' + datetime.format('DD-MM-YY') + '</strong> om <strong>' + datetime.format('HH:mm') + '</strong>'
}

// Turns a relative href of a resource to an absolute one
export const fromStorage = href => {
  return axios.defaults.baseURL ? axios.defaults.baseURL + href : href
}


// Turns a relative href of an image to an absolute one, falls back to "no image" placeholder
export const imageFromStorage = href => {
  href = href || '/no-image.png'
  return axios.defaults.baseURL ? axios.defaults.baseURL + href : href
}

// Nice formatting of a file's size
export const formatBytes = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals || 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

// As found on https://stackoverflow.com/questions/7467840/nl2br-equivalent-in-javascript
export const nl2br = function (str, isXhtml) {
  if (typeof str === 'undefined' || str === null) {
    return ''
  }
  const breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br />' : '<br>'
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

// If single word, returns first 2 letters of word, otherwise initials of each of the first 2 words
export const getAvatarText = function (value) {
  if (value.indexOf(' ') === -1) {
    return value.substring(0, 2)
  } else {
    const words = value.split(' ')
    return words[0][0] + (words[1][0] ? words[1][0] : '.')
  }
}

// Returns day and month
export const dayMonthFromDateTime = function (datetimeIso) {
  return moment(datetimeIso).format('D MMM')
}

// Returns militaryish hour (09:30, 13:50 etc)
export const hourMinutesFromDateTime = function (datetimeIso) {
  return moment(datetimeIso).format('HH:mm')
}

// Returns time difference between two datetimeiso's
export const differenceFromDateTime = function (datetimeEnd, datetimeStart) {
  return moment.duration(moment(datetimeEnd).diff(moment(datetimeStart))).humanize()
}

// Uppercase first letter of string/sentence
export const ucfirst = text => {
  return text[0].toUpperCase() + text.slice(1)
}

export const toFixed = value => {
  return value && !isNaN(value) ? (Math.round(value * 4) / 4).toFixed(2) : null
}

// Returns day, month and year and hour:minutes in human readable format
export const prettyDateFormat = function (datetimeIso) {
  return moment(datetimeIso).format('D MMM YYYY HH:mm')
}

export const lpad = function (val, length, padStr = '0') {
  return `${val}`.padStart(length, padStr)
}

export default {
  hourFromDateTime,
  relativeDateFromDateTime,
  niceDateFromDateTime,
  dayMonthFromDateTime,
  hourMinutesFromDateTime,
  differenceFromDateTime,
  fromStorage,
  imageFromStorage,
  formatBytes,
  nl2br,
  getAvatarText,
  ucfirst,
  toFixed,
  lpad,
  prettyDateFormat,
  dateOnOm
}
