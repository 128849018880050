<template>
  <v-row class="mb-3 justify-center">
    <h2>{{ getTitle }}</h2>
  </v-row>
</template>
<script>
export default {
  props: {
    workOrder: {
      type: Object,
      required: true,
    },
  },

  computed: {
    getTitle() {
      const workOrder = this.workOrder
      let title = workOrder.standard_name_prefix + ' ('
      if(workOrder.is_for_equipment_movement) {
        if(workOrder.location.name) {
          title += workOrder.location.name + ', ' + workOrder.location.city
        } else {
          title += workOrder.location.location
        }
      } else {
        title += workOrder.client.name
      }
      title += ')'
      return title
    },
  },
};
</script>