<!-- <template>
  <div>
    <v-text-field
      ref="input"
      v-model="readableDateValue"
      :label="label"
      mask="##/##/####"
      return-masked-value
      prepend-icon="event"
      @click:prepend="defaultPrependCb"
      @input="save"
    />
    <div v-if="renderMenu">
      <v-menu
        ref="vdp"
        v-model="menu"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        full-width
        nudge-right="200px"
        max-width="290px"
        min-width="290px"
        :return-value.sync="dateValue"
        :attach="this.$refs.input.$el"
      >
        <v-date-picker
          v-model="validatedDateValue"
          type="date"
          no-title
          scrollable
          autosave="true"
          :min="minDate"
          :max="maxDate"
          @input="$refs.vdp.save(dateValue); save(dateValue)"
        />
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Datepicker',
  props: {
    model: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    minDate: {
      type: String,
      required: false,
      default: undefined,
    },
    maxDate: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      validatedDateValue: null,
      renderMenu: false,
      menu: false,
    }
  },
  computed: {
    readableDateValue: {
      get() {
        if (!this.dateValue) {
          return ''
        }

        const [year, month, day] = this.dateValue.split('-')

        return `${day}/${month}/${year}`
      },
      set(value) {
        const [day, month, year] = value.split('/')
        this.dateValue = `${year}-${month}-${day}`
      },
    },

    dateValue: {
      get() {
        return this.validatedDateValue
      },
      set(value) {
        if (this.checkDate(value)) {
          this.validatedDateValue = value
        }
      },
    },
  },
  watch: {
    'model': function (value) {
      this.validatedDateValue = this.model
    },
  },
  mounted() {
    this.validatedDateValue = this.model
  },
  methods: {
    defaultPrependCb() {
      this.renderMenu = true
      this.showMenu()
    },
    save(model) {
      this.$emit('update:model', this.dateValue)
    },
    showMenu() {
      this.$nextTick(() => {
        this.menu = true
      })
    },
    checkDate(dateString) {
      let dt, year, month, day

      // bail if we get null
      if (!dateString) {
        return false
      }
      const dateParts = dateString.split('-')

      // bail if we do not have year, month and day
      if (dateParts.length < 3) {
        return false
      }

      // parse year, month and day from the string value
      const y = parseInt(dateParts[0])
      const m = parseInt(dateParts[1])
      const d = parseInt(dateParts[2])

      try {
        // create the date object with the values sent in (month is zero based)
        dt = new Date(y, m - 1, d, 0, 0, 0, 0)

        // get the month, day, and year from the object we just created
        month = dt.getMonth() + 1
        day = dt.getDate()
        year = dt.getYear() + 1900

        // if they match then the date is valid
        if (month === m && year === y && day === d) {
          return true
        } else {
          return false
        }
      } catch (e) {
        // eslint-disable-next-line
        console.warn('validation failed', year, month, day)
        return false
      }
    },
  },
}
</script> -->
<template>
  <div>
    <VueDatePicker
      :placeholder="placeholder"
      :name="name"
      :min-date="minDate ? new Date(minDate) : ''"
      :max-date="maxDate ? new Date(maxDate) : ''"
      :enable-time-picker="false"
      :week-start="1"
      :auto-apply="autoApply"
      :no-today="noToday"
      :disabled="disabled"
      :required="required"
      :readonly="readonly"
      :position="position"
      :model-value="model"
      @update:model-value="updateModel"
      :text-input="textInputOptions"
      :format="format"
    />
  </div>
</template>

<script setup>
import emitArray from '@/utils/emit';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment';
import { defineComponent, ref, onMounted } from 'vue';

const emit = defineEmits(emitArray.value)

defineComponent({
  VueDatePicker,
})

const model = ref()

const removeUnexpectedCharacters = (value) => {
  return value.replaceAll(/[\/\-:.,]/g, '')
}

const textInputOptions = {
  format: (date) => {  
    const clearDate = removeUnexpectedCharacters(date)

    const length = clearDate.length;
    const currentDate = new Date();

    if(length <= 2 && clearDate < currentDate.getDate()) {
      return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, clearDate)
    } else if (length <= 2 && clearDate > currentDate.getDate()) {
      return new Date(currentDate.getFullYear(), currentDate.getMonth(), clearDate)
    } else if (length > 2 && length <= 4) {
      const day = clearDate.substring(0, 2);
      const month = clearDate.substring(2, 4);

      return new Date(currentDate.getFullYear(), month - 1 , day)
    } else if (length > 4 && length == 6) {
      const day = clearDate.substring(0, 2);
      const month = clearDate.substring(2, 4);
      const year = clearDate.substring(4, 6);

      return new Date(`${month}/${day}/${year}`)
    } else if (length > 4 && length == 8) {
      const day = clearDate.substring(0, 2);
      const month = parseInt(clearDate.substring(2, 4));
      const year = clearDate.substring(4, 8);

      return new Date(`${month}/${day}/${year}`)
    } 
  }
};

const props = defineProps({
  placeholder: {
    type: String,
    required: false
  },
  name: {
    type: String,
    required: false
  },
  minDate: {
    type:  [Object, String],
    required: false,
  },
  maxDate:  {
    type:  [Object, String],
    required: false,
  },
  autoApply: {
    type: Boolean,
    required: false,
    default: true
  },
  noToday: {
    type: Boolean,
    required: false,
    default: false
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  readonly: {
    type: Boolean,
    required: false,
    default: false
  },
  required: {
    type: Boolean,
    required: false
  },
  position: {
    type: String ,
    required: false,
    default: 'center'
  },
  modelValue: {
    required: false,
  }
})

onMounted(() => {
  model.value = props.modelValue
})

const updateModel = (newValue) => {
  model.value = newValue;
  emit('update-dates-and-times', { name: props.name, value: newValue})
  emit('update:value-datetime', moment(newValue).format('YYYY-MM-DD'))
}

const format = () => {
  if (!model.value) {
    return ;
  }

  const day = (new Date(model.value).getDate()).toString().padStart(2, "0");
  const month =  (new Date(model.value).getMonth() + 1).toString().padStart(2, "0");
  const year =  new Date(model.value).getFullYear();

  return `${day}/${month}/${year}`;
}

</script>

