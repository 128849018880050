<template>
  <v-row>
    <v-col sm="6" offset-sm="3" xs="12" class="about-content">
      <section>
        <p class="pb-3">This application is developed by</p>
        <p class="pb-3">
          <img @click="clicks += 1" src="@/assets/logo-eenvoud.png" alt="Eenvoud">
        </p>
      </section>

      <section>
        <p class="pb-3">Contact</p>
        <p class="pb-3">
          <a href="mailto:contact@eenvoud.nl">contact@eenvoud.nl</a>
        </p>
        <p class="pb-3">
          for support
        </p>
      </section>

      <section>
        <p class="pb-3">Logged in as</p>
        <p class="pb-3">
          <strong>{{ user.email }}</strong><br>
          <strong>{{ user.username }}</strong>
        </p>
      </section>

      <section>
        <p class="pb-3">Sha</p>
        <p class="pb-3">
          <strong>{{ hash }}</strong>
        </p>
      </section>

      <section v-if="debugMode">
        <p class="pb-3">For developers</p>
        <v-btn @click="triggerError" color="error">Trigger error</v-btn>
      </section>
    </v-col>
  </v-row>
</template>

<script>
export default {

  data () {
    return {
      clicks: 0,
    }
  },

  computed: {
    user() {
      return this.$store.getters.user
    },
    hash() {
      // eslint-disable-next-line no-undef
      return __COMMIT_HASH__ || ''
    },
    debugMode() {
      return this.clicks >= 5 || process.env.NODE_ENV === 'development'
    }
  },

  methods: {
    triggerError() {
      throw new Error('This is a test error')
    }
  }
}
</script>

<style scoped lang="scss">
.about-content {
  text-align: center;
}
section {
  border-bottom: 1px solid #ddd;
  padding-top: 16px;
  &:last-of-type {
    border-bottom: none;
  }
}
</style>
