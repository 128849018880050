import * as Sentry from '@sentry/vue'

const appStore = {
  state: {
    pageTitle: '',
    user: {},
  },
  getters: {
    'appGetPageTitle': state => {
      return state.pageTitle
    },

    'user': state => {
      return state.user
    },
  },
  mutations: {
    'app.setPageTitle': (state, payload) => {
      state.pageTitle = payload
    },

    'app.setUser': (state, payload) => {
      state.user = payload

      Sentry.setUser({
        id: payload.id,
        username: payload.name,
        email: payload.email,
      })
    },
  },
  actions: { },
}

export default appStore
