<template>
    <div class="material-list mb-7">
        <v-table class="table v-table table-striped" density="compact">
            <template v-for="location in equipment">
                <thead>
                <tr>
                    <td colspan="10" style="padding-top: 30px !important; padding-bottom: 10px !important; color: #1976d2;">
                        <template v-if="asForm">
                            <span v-if="location[0].location.id === workOrder.location.id" class="current-location">Locatie van deze werkorder</span>
                            <span v-else class="other-location">Andere locatie van dit project</span>
                        </template>
                        {{ location[0].location.location }}
                        <a :href="`https://maps.google.com/?q=${getGoogleMapsUrl(location[0].location)}`" target="_blank"
                            style="display: inline-block; margin-left: 10px; color: #666;">
                            Open in Google Maps<v-icon style="font-size: 14px; margin-left: 5px;">mdi-open-in-new</v-icon>
                        </a>
                    </td>
                </tr>
                </thead>
                <thead>
                <tr>
                    <th class="td-equipment-img"></th>
                    <th colspan="2" v-if="asForm" class="no-wrap">Aantal opgehaald</th>
                    <th class="text-left no-wrap">Aantal</th>
                    <th class="text-left no-wrap">Materiaal</th>
                    <th class="text-left no-wrap">Ingehuurd van</th>
                    <th class="text-left no-wrap">Systeem gemaakt</th>
                    <th class="text-left no-wrap">Geplaatst op</th>
                    <th class="text-left no-wrap">Geplaatst via werkorder</th>
                    <th class="text-left no-wrap">Opgehaald op</th>
                    <th class="text-left no-wrap">Opgehaald via werkorder</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(equipmentItem, index) in location" :key="index" :class="greyOutCollectedEquipment && equipmentItem.collected_at ? ' greyed-out' : ''">
                    <td class="text-center no-wrap"><img v-if="equipmentItem.image_url" :src="equipmentItem.image_url" class="equipment-img"></td>
                    <td v-if="asForm && !equipmentItem.collected_at">
                        <v-text-field
                            v-model="equipmentItem.quantity"
                            :name="equipmentItem.quantity"
                            :max="equipmentItem.count"
                            :min="0"
                            type="number"
                            variant="outlined"
                            density="compact"
                            class="quantity"
                            hide-details
                        />
                    </td>
                    <td v-if="asForm && !equipmentItem.collected_at">
                        <div class="copyQuantity" @click="copyQuantity(location, equipmentItem, equipmentItem.count)">
                            <v-icon>mdi-arrow-left-bold-circle</v-icon>
                        </div>
                    </td>
                    <td v-if="asForm && equipmentItem.collected_at" colspan="2" class="no-wrap">
                        Is al opgehaald
                    </td>
                    <td class="text-left no-wrap">{{ equipmentItem.count }}</td>
                    <td class="text-left no-wrap">{{ equipmentItem.name }}</td>
                    <td class="text-left no-wrap">{{ equipmentItem.rental_supplier_name }}</td>
                    <td class="text-left no-wrap">{{ getSystemCreatedValue(equipmentItem) }}</td>
                    <td class="text-left no-wrap">{{ locationDate(equipmentItem.placed_at, false) }}</td>
                    <td class="text-left no-wrap">{{ equipmentItem.placed_by_work_order }}</td>
                    <td class="text-left no-wrap">{{ equipmentItem.is_consumable ? 'n.v.t.' : locationDate(equipmentItem.collected_at, false) }}</td>
                    <td class="text-left no-wrap">{{ equipmentItem.is_consumable ? 'n.v.t.' : equipmentItem.collected_by_work_order }}</td>
                </tr>
                </tbody>
            </template>
        </v-table>
    </div>

    <div v-if="asForm" class="mt-12 mb-12">
        <v-btn color="primary" style="width: 110px;" @click="submitMaterialList">
            <template v-if="!formSubmitted">
                Ophalen
            </template>
            <template v-else>
                <v-progress-circular
                    indeterminate
                    color="white"
                    width="2"
                    size="24"
                ></v-progress-circular>
            </template>
        </v-btn>
        <v-btn flat :to="{ name: 'WorkOrdersView', params: { id: workOrder.id } }" class="ml-12">
          Annuleren
        </v-btn>
    </div>
</template>
<script>
import { bulkUpdateEquipmentLocations } from '@/api/equipment'
import SayError from '@/utils/SayError'

export default {
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
        equipment: {
            type: Object,
            required: true,
            default: () => {},
        },
        greyOutCollectedEquipment: {
            type: Boolean,
            default: true,
        },
        asForm: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            formSubmitted: false,
        }
    },
    computed: {
        postFormData() {
            const selectedItemsData = Object.values(this.equipment).reduce((acc, location) => {
                location.forEach(item => {
                    // if item has a value for collected_at, remove it from this list
                    if (item.collected_at || item.quantity === '0') {
                        return;
                    }
                    item.amount = item.quantity;

                    if (item.quantity) {
                        acc.push(item);
                    }
                });
                return acc;
            }, []);

            if (!selectedItemsData.length) {
                return;
            }

            let data = {}

            // This need to be split between composite and non-composite items
            data.compositeItems = [];
            data.normalItems = [];
            for (const compositeItem of selectedItemsData.filter((item) => {return item.composite_equipment_id !== null})) {
                data.compositeItems.push({
                    HASH: compositeItem.HASH,
                    amount: compositeItem.amount,
                })
            }
            for (const normalItem of selectedItemsData.filter((item) => {return item.composite_equipment_id === null})) {
                data.normalItems.push({
                    HASH: normalItem.HASH,
                    amount: normalItem.amount,
                })
            }

            return data
        },
    },
    methods: {
        getLocationAddress(location) {
            return location ? location.location : '';
        },
        locationDate(dateTimeString, withTimeInclSeconds = true, withTime = true) {
            if (! dateTimeString) return ''
            dateTimeString = dateTimeString.toString()
            let parts = []
            if (dateTimeString.includes('T')) {
                parts = dateTimeString.split('T')
            } else {
                parts = dateTimeString.split(' ')
            }
            const date = parts[0];
            const time = parts[1];
            const dateParts = date.split('-');
            const timeParts = time.split(':');

            if (withTimeInclSeconds) return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]} ${timeParts[0]}:${timeParts[1]}:${timeParts[2]}`;

            if (withTime) return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]} ${timeParts[0]}:${timeParts[1]}`;

            return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        },
        getGoogleMapsUrl(location) {
            if (location.latitude && location.longitude) {
                return `${location.latitude},${location.longitude}`;
            }
            return encodeURIComponent(this.getLocationAddress(location));
        },
        copyQuantity(location, equipmentItem, quantity) {
            this.equipment[location[0].location.id].forEach(item => {
                if (item.id === equipmentItem.id) {
                    item.quantity = quantity + ''
                }
            });
        },
        async submitMaterialList() {
            if(this.formSubmitted) return

            this.formSubmitted = true

            if(!this.postFormData) {
                alert('Geen materiaal geselecteerd')
                this.formSubmitted = false
                return
            }

            const requestData = {
                ...this.postFormData,
                ...{
                    location_id: this.workOrder.location_id,
                    work_order_id: this.workOrder.id,
                    location_change_type: 'collect',
                    process_grouped: true,
                    job_id: this.workOrder.job_id,
                    location_id: this.workOrder.location_va.id,
                }
            }

            try {
                await bulkUpdateEquipmentLocations(requestData)
                // redirect to work order view
                this.$router.push({ name: 'WorkOrdersView', params: { id: this.workOrder.id } });
            } catch (err) {
                this.formSubmitted = false
                SayError(err)
                
            }
        },
        getSystemCreatedValue(item) {

            let value = ''

            if(item.system_created !== 0 && item.system_created !== '0') {
                if(item.system_created === item.total_items && !item.composite_equipment_id) {
                    value = 'Ja';
                } else if (item.system_created > 0) {
                    value = `${item.system_created}/${item.total_items}`
                }
            }

            return value;
        },
    },
};
</script>

<style lang="scss">
.greyed-out {
  color: #AAA;
}
.greyed-out img {
  opacity: 0.5;
}
.equipment-img {
  max-width: 40px;
  max-height: 30px;
  display: block;
  margin: 0 auto;
}
.no-wrap {
  white-space: nowrap;
}
.td-equipment-img {
  min-width: 70px !important;
}

.v-input.quantity {
  max-width: 80px;
  min-width: 80px;
  width: 80px;
  .v-field__input {
    padding:0;
    text-indent: 10px;
    font-size: 14px;
    height: 35px !important;
    min-height: 35px !important;
    line-height: 35px !important;
  }
}
.v-input.autocomplete {
  max-width: 80%;
  min-width: 80%;
  width: 80%;
  .v-field__input {
    padding:0;
    text-indent: 10px;
    font-size: 14px;
    height: 35px !important;
    min-height: 35px !important;
    line-height: 35px !important;
  }
}
.v-input--error.v-input.quantity  .v-field__input {
  background: rgba(255,150,150,0.75);
}
.copyQuantity {
  color: rgb(25, 118, 210);
  cursor: pointer;
}
.current-location {
    background-color: rgb(25, 118, 210);
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    padding: 2px 10px;
    font-size: 12px;
    margin-right: 10px;
}
.other-location {
    border: 1px solid rgb(25, 118, 210);
    border-radius: 10px;
    display: inline-block;
    padding: 2px 10px;
    font-size: 12px;
    margin-right: 10px;
}
</style>
