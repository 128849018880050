import axios from 'axios'

export function initInterceptors() {

  axios.interceptors.request.use(
    (request) => {
      request.headers.Accept = 'application/json'
      if(!request.headers['Content-Type']) {
        request.headers['Content-Type'] = 'application/json'
      }
      return request
    }
  )

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data if needed
      return response;
    }, 
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error if needed
      if ([401, 403, 404].includes(error.response.status)) {
        // eenvauth
        return Promise.reject(error);
      } else {
        const message = (error.response.data && error.response.data.message) ? error.response.data.message : error.response.statusText;
        throw new Error(message);
      }
    }
  );
  
}

export default {
  initInterceptors,
}
