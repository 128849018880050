<template>
  <StartActivity :work-order="workOrder" />
</template>

<script>
import StartActivity from './StartActivity'

export default {

  components: {
    StartActivity,
  },
  props: {
    workOrder: {
      type: Object,
      required: true,
    },
  },
}
</script>
