<template>
  <TitleBar :workOrder="workOrder"/>
  <template v-if="workOrder.status.name === 'APPROVED'">
    Werkorder is goedgekeurd en kan niet meer worden aangepast.
  </template>
  <template v-else>
  <v-row class="flex-column">
    <div class="headline">
      Wie voert deze activiteit uit?
    </div>

    <v-row class="align-center">
      <v-text-field
        v-model="searchString"
        label="Zoek persoon"
        clearable
        @keyup.enter="search"
        @click:clear="search"
        variant="underlined"
      />

      <v-btn
        color="primary"
        :disabled="!searchString"
        @click="search"
        class="ml-4"
      >
        Zoek
      </v-btn>
    </v-row>

    <v-row class="flex-column">
      <v-row v-for="(peoplePair, index) in peoplePairs" :key="index">
        <v-col v-for="person in peoplePair" :key="person.id" xs="6" class="pa-0" style="height: 40px;">
          <v-checkbox v-model="selectedPeople[person.id]" :label="person.name" hide-details density="compact"/>
        </v-col>
      </v-row>
    </v-row>
  </v-row>
  </template>
</template>

<script>
import { compact, chunk, zip } from 'lodash-es'
import TitleBar from '@/components/WorkOrders/TitleBar'
import { updateWorkOrderOngoingStatus } from '@/api/workOrders'
import { fetchEmployees } from '@/api/employees'
import Say from '@/utils/Say'

import bus from '@/utils/eventBus'

export default {
  components: {
    TitleBar,
  },
  props: {
    workOrder: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      people: [],
      selectedPeople: {},

      searchString: '',
    }
  },

  computed: {
    peoplePairs() {
      return zip(...chunk(this.people, Math.ceil(this.people.length / 2))).map(compact)
    },
    selectedPeopleEmployeeIds() {
      return Object.entries(this.selectedPeople).reduce((acc, [key, value]) => {
        if (value) {
          acc.push(parseInt(key, 10))
        }
        return acc
      }, [])
    },
  },

  watch: {
    'workOrder.employees': {
      immediate: true,
      deep: true,
      handler(employees) {
        if (employees) {
          const selectedPeople = {}

          for (const employeeId of employees) {
            if (!employeeId.id) continue;
            selectedPeople[employeeId.id] = true
          }

          this.selectedPeople = selectedPeople
        } else {
          this.selectedPeople = {}
        }
      },
    },
  },

  created() {
    bus.on('start-timer:cancel', this.cancel)
    bus.on('start-timer:start', this.start)

    bus.emit('work-order-footer:set-actions', {
      left: {
        text: 'Cancel',
        event: 'start-timer:cancel',
      },
      right: {
        text: 'Start',
        event: 'start-timer:start',
      },
    })

    this.fetchPeople()
  },

  beforeUnmount() {
    bus.emit('work-order-footer:clear-actions')

    bus.off('start-timer:cancel', this.cancel)
    bus.off('start-timer:start', this.start)
  },

  methods: {
    cancel() {
      this.$router.back()
    },

    search() {
      this.fetchPeople()
    },

    start() {
      if (!this.selectedPeopleEmployeeIds.length) {
        Say('error', 'Selecteer ten minste 1 persoon.')
        return
      }

      const employeeIds = this.selectedPeopleEmployeeIds

      updateWorkOrderOngoingStatus(this.workOrder.id, {
        people: employeeIds,
      }).then(({ data }) => {
        const { status, statusOngoing } = data

        this.$store.commit('workOrders.updateView', { key: 'employees', value: employeeIds })
        this.$store.commit('workOrders.updateView', { key: 'status', value: status })
        this.$store.commit('workOrders.updateView', { key: 'status_ongoing', value: statusOngoing })
        this.$store.commit('workOrders.updateView', { key: 'last_time', value: (new Date()).toISOString() })

        this.$router.push({ name: 'WorkOrdersViewOverview', params: { id: this.workOrder.id } })
      })
    },

    fetchPeople() {
      const params = {}
      if (this.searchString) {
        params.search = this.searchString
      }

      fetchEmployees(params).then(({ data }) => {
        this.people = data.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.headline {
  text-align: center;
  font-size: 24px;
}

.v-input--checkbox {
  margin-top: 8px;
  .v-input__slot {
    margin-bottom: 0;
  }

  .v-messages {
    min-height: 0;
  }
}
</style>
