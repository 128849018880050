import { cloneDeep } from 'lodash-es'

import { fetchWorkOrders, fetchWorkOrder } from '@/api/workOrders'
import { workOrderTransformer } from '../utils/transformers'

const emptyWorkOrderObject = {
  client_id: null,
  date_start: null,
  time_start: null,
  inverse: false,
  employees: [],
  employee_id: null,
  location: null,
  location_va: 1,
  status: {
    name: 'PLANNED'
  },
  files: [],
  instructions: '',
  category: '',
}

const workOrdersStore = {
  state: {
    mine: {
      list: [],
      total: 0,
    },
    all: {
      list: [],
      total: 0,
    },
    view: {},
    locations: {
      vanAmerongen: [{
        id: 1, location: 'Van Amerongen HQ',
      }],
      client: [],
    },
  },
  getters: {
    'workOrdersMineList': state => {
      return state.mine.list
    },
    'workOrdersMineTotal': state => {
      return state.mine.total
    },
    'workOrdersAllList': state => {
      return state.all.list
    },
    'workOrdersAllTotal': state => {
      return state.all.total
    },
    'workOrdersView': state => {
      return state.view
    },
    'workOrdersLocations': state => {
      return state.locations
    },
  },
  mutations: {
    'workOrders.setMineList': (state, payload) => {
      state.mine.list = payload
    },
    'workOrders.setMineTotal': (state, payload) => {
      state.mine.total = payload
    },
    'workOrders.setAllList': (state, payload) => {
      state.all.list = payload
    },
    'workOrders.setAllTotal': (state, payload) => {
      state.all.total = payload
    },
    'workOrders.setView': (state, payload) => {
      state.view = payload
    },
    'workOrders.updateView': (state, payload) => {
      state.view[payload.key] = payload.value
    },
    'workOrders.setLocations': (state, payload) => {
      state.locations[payload.key] = payload.value
    },
    'workOrders.clearLocations': state => {
      state.locations.client = []
    },
  },
  actions: {
    'workOrders.fetchMine': ({ commit }, data) => {
      return new Promise((resolve, reject) => {
        fetchWorkOrders(Object.assign(
          data,
          { currentUser: true, noDraft: true }
        )).then(res => {
          commit('workOrders.setMineList', res.data.data)
          commit('workOrders.setMineTotal', res.data.total)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    'workOrders.fetchAll': ({ commit }, data) => {
      return new Promise((resolve, reject) => {
        fetchWorkOrders(Object.assign(
          data,
          { noDraft: true }
        )).then(res => {
          commit('workOrders.setAllList', res.data.data)
          commit('workOrders.setAllTotal', res.data.total)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    'workOrders.fetchSingle': ({ commit, state }, id) => {
      return new Promise((resolve, reject) => {
        fetchWorkOrder(id)
        .then(res => {
          const workOrder = workOrderTransformer(res.data)
          commit('workOrders.setView', workOrder)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
  },
}

export default workOrdersStore
