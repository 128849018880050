<template>
  <TitleBar :workOrder="workOrder"/>
  <div class="history-wrapper">
    <Card
      :random-color="true"
      :no-padding="true"
      :avatar-text="items.length"
      title="Geschiedenis"
      description="Alle aanpassingen die aan dit werkorder zijn gedaan"
    >
      <template v-slot:content>
        <v-row v-for="(item, index) in items" :key="index" class="item">
          <v-col sm="8" class="action py-0">
            <p>{{ item.action }}</p>
          </v-col>
          <v-col sm="4" class="meta py-0">
            <p>
              {{ relativeTime(item.when) }}<br>
              {{ item.name }}
            </p>
          </v-col>
        </v-row>
      </template>
    </Card>
  </div>
</template>

<script>
import moment from 'moment'
import { fetchWorkOrderHistory } from '@/api/workOrders'
import Card from '@/components/Card'
import TitleBar from '@/components/WorkOrders/TitleBar'

export default {
  components: {
    Card,
    TitleBar,
  },

  data() {
    return {
      items: [],
    }
  },
  computed: {
    workOrder() {
      return this.$store.getters.workOrdersView
    },
  },
  mounted() {
    if (this.workOrder.id) {
      fetchWorkOrderHistory(this.workOrder.id)
        .then(res => {
          this.items = res.data
        })
    }
  },
  methods: {
    relativeTime(value) {
      return moment(value).fromNow()
    },
  }
}
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
.history-wrapper {
  width: 100%;
}
.item {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  .action {
    p {
      font-size: 16px;
      line-height: 38px;
    }
  }
  .meta {
    font-size: 13px;
    text-align: right;
    color: #999;
  }
}
</style>
