<template>
  <v-row class="card-header align-center">
    <div v-if="avatarText" class="avatar-wrapper">

      <v-avatar color="grey-lighten-4" :size="String(avatarText).length > 3 ? '60' : '40'">
        <span class="headline" :class="avatarClass()">{{ avatarText }}</span>
      </v-avatar>
    </div>

    <div class="text">
      <h2 class="text-h5">{{ title }}</h2>
      <p v-if="description" class="text-subtitle-1">
        {{ description }}
      </p>
    </div>

    <!-- Contextual menu -->
    <div class="button-more-wrapper">
      <v-menu v-if="actions" position="bottom left">
        <v-btn slot="activator" icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
        <v-list>
          <v-list-item v-for="(action, index) in actions" :key="index">
            <v-list-item-title @click="action.method">
              {{ action.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-if="button"
        icon
        :color="button.color"
        fab
        :dark="button.dark !== undefined ? button.dark : false"
        size="small"
        @click="button.action"
      >
        <v-icon>{{ button.icon }}</v-icon>
      </v-btn>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'CardHeader',
  props: {
    avatarText: {
      type: [String, Number],
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    actions: {
      type: Array,
      required: false,
      default: undefined,
    },
    button: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  setup($props) {
    const avatarClass = () => {
      return {
        'text-body-1': String($props.avatarText).length > 3 && String($props.avatarText).length <= 5,
        'text-smaller': String($props.avatarText).length > 5,
      };
    }

    return {
      avatarClass,
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-wrapper {
  text-align: center;
  .headline {
    text-transform: uppercase;
  }
}
.button-more-wrapper {
  text-align: right;
}
.text {
  &:not(:first-child) {
    padding-left: 10px;
  }

  h2 {
    font-weight: normal;
  }
  p {
    margin-bottom: 0;
    color: #999;
  }
}

.text-smaller {
  font-size: 12px;
}
</style>
