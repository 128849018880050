<template>
  <v-card flat>
    <v-card-title>
      <v-row class="justify-space-between flex-wrap">
        <CardHeader
          title="Zoek naar werkorder"
          description="Zoek naar een specieke werkorder op een nummer. Vul het nummer in en je zoekt automatisch door alle actieve werkorders heen."
        />
      </v-row>
    </v-card-title>

    <v-card-text>
      <!-- List of work orders -->
      <v-text-field v-model="search" placeholder="08668" variant="underlined" />

      <template v-if="results && results.length">
        <v-row
          v-for="(workOrder, index) in results"
          :key="index"
          class="work-order align-center flex-wrap"
          @click="goToWorkOrder(workOrder.id)"
        >
          <v-col sm="5" md="5">
            {{ workOrder.project_name }} <br>
            {{ lpad(workOrder.id, 5) }}
          </v-col>
          <v-col sm="4" md="4">
            <h3>{{ workOrder.client?.name ?? '' }}</h3>
            <span>{{ workOrder.category }}</span>
          </v-col>
          <v-col sm="3" md="3">
            <template v-if="workOrder.location">
              {{ formatLocation(workOrder.location) }}
              <br>
            </template>
            {{ prettyDateFormat(workOrder.datetime_start) }}
          </v-col>
        </v-row>
      </template>
      <template v-else-if="loading">
        <v-row>
          <v-col xs="12">
            Bezig met laden...
          </v-col>
        </v-row>
      </template>
      <template v-else-if="search">
        <v-row>
          <v-col xs="12">
            Geen resultaten
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col xs="12">
            Begin met typen om te zoeken
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import CardHeader from '@/components/Card/Header'
import text from '@/mixins/text'
import { fetchWorkOrders } from '@/api/workOrders'
import { debounce } from 'lodash-es'
import { lpad, prettyDateFormat } from '@/utils/filters'

export default {
  components: {
    CardHeader,
  },
  mixins: [text],
  props: {
  },
  data() {
    return {
      search: '',
      results: null,
      loading: false,
      lpad: lpad,
      prettyDateFormat: prettyDateFormat
    }
  },
  watch: {
    search: {
      handler: debounce(function () {
        this.searchWorkorders()
      }, 800),
    },
  },
  methods: {
    searchWorkorders() {
      this.loading = true
      fetchWorkOrders(Object.assign(
        {
          rowsPerPage: 15,
          sortBy: 'datetime_start',
          descending: false,
          showHistoric: true,
          noDraft: true,
          's-id': this.search,
        },
        { noDraft: true }
      )).then(res => {
        this.results = res.data.data
        this.loading = false
      }).catch(() => {
        this.loading = true
      })
    },

    goToWorkOrder(id) {
      this.$router.push({ name: 'WorkOrdersView', params: { id } })
    },

    formatLocation(location) {
      const streetParts = [location.street]
      if (location.street_number) {
        streetParts.push(location.street_number)
      }
      const street = streetParts.join(' ')

      const addressParts = [street]
      if (location.city) {
        addressParts.push(location.city)
      }
      return addressParts.join(', ')
    },
  },
}
</script>

<style scoped lang="scss">
  // Copied varbatim from ListBox.vue
  .headline {
    text-transform: uppercase;
  }
  .card {
    .card__text {
      padding: 5px;
    }
  }
  .condensed {
    .work-order {
      padding: 6px 0;
      .work-order-info, .work-order-hours {
        line-height: 18px;
      }
      .work-order-status {
        span {
          font-size: 14px;
        }
      }
    }
  }
  .work-order {
    padding: 12px 0;
    border-bottom: 1px solid #ddd;
    &:last-of-type {
      border-bottom: none;
    }
    .work-order-avatar {
      text-align: center;
    }
    .work-order-info {
      padding-left: 10px;
      p {
        margin-bottom: 0;
      }
    }
    .work-order-status {
      span {
        font-size: 16px;
      }
    }
    .work-order-hours {
      .hour-start {
        font-size: 16px;
        font-weight: bold;
      }
      .day-start {
        color: #999;
      }
    }
  }
  .view-all {
    text-align: center;
    font-size: 16px;
    a {
      text-decoration: none;
    }
  }
  .btn--bottom.btn--absolute.btn--small {
    right: -10px;
  }
  .no-workorders {
    padding-left: 20px;
  }
  .show-finished-workorders {
    min-width: 160px;
  }
  .show-historic-workorders {
    min-width: 190px;
  }

  .v-card__title {
    padding: 16px 8px 0;
  }
  .v-card__text {
    padding: 8px;
  }
</style>
