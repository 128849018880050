<template>
  <div>
    <!-- Workorders -->
    <WorkOrdersListBox
      v-scroll="onScroll"
      :title="title"
      :description="description"
      :work-orders-list="workOrders"
      :work-orders-total="workOrdersTotal"
      :loading="loading"

      :show-create-button="true"
      @filterFinished="handleFilterFinished"

      @filterHistoric="handleFilterHistoric"
    />

    <v-btn
      v-if="canLoadMore"
      :loading="loading"
      class="load-more"
      block
      @click="fetchWorkOrders"
    >
      Laad meer
      <template v-slot:loader>
        <span><v-progress-circular indeterminate /> Bezig met laden...</span>
      </template>
    </v-btn>
  </div>
</template>

<script>
import { throttle } from 'lodash-es'

import { fetchWorkOrders } from '@/api/workOrders'

import text from '@/mixins/text'

import WorkOrdersListBox from './ListBox'

export default {
  components: {
    WorkOrdersListBox,
  },

  mixins: [text],

  data() {
    return {
      title: '',
      description: '',
      page: 1,
      rowsPerPage: 20,
      loading: false,
      showFinished: false,
      showHistoric: false,
      workOrders: [],
      workOrdersTotal: null,
    }
  },

  computed: {
    source() {
      return this.$route.params.source
    },

    canLoadMore() {
      return (this.workOrdersTotal === null) || (this.workOrders.length < this.workOrdersTotal)
    },
  },

  created() {
    if (this.source === 'mine') {
      this.title = this.text.workOrders.mine.title
      this.description = this.text.workOrders.mine.description
    } else {
      this.title = this.text.workOrders.all.title
      this.description = this.text.workOrders.all.description
    }

    this.$store.commit('app.setPageTitle', this.title)

    this.fetchWorkOrders()
  },

  methods: {
    fetchWorkOrders() {
      if (!this.canLoadMore || this.loading) return

      this.loading = true

      const params = {
        rowsPerPage: this.rowsPerPage,
        page: this.page,
        sortBy: 'datetime_start',
        descending: 'false',
        noDraft: true,
      }

      if (this.source === 'mine') {
        params.currentUser = true
      }

      if (!this.showFinished) {
        params.status = 'DRAFT,PLANNED,ONGOING'
      }

      if (this.showHistoric) {
        params.showHistoric = true
      }

      fetchWorkOrders(params).then(({ data: { data: workOrders, total } }) => {
        this.workOrders.push(...workOrders)
        this.workOrdersTotal = total

        const totalPages = Math.ceil(total / this.rowsPerPage)

        if (this.page < totalPages) {
          this.page = this.page + 1
        }
      }).finally(() => {
        this.loading = false
      })
    },

    clearWorkOrders() {
      this.page = 0
      this.workOrders = []
      this.workOrdersTotal = null
    },

    handleFilterFinished({ show }) {
      this.showFinished = show
      this.clearWorkOrders()
      this.fetchWorkOrders()
    },

    handleFilterHistoric({ show }) {
      this.showHistoric = show
      this.clearWorkOrders()
      this.fetchWorkOrders()
    },

    onScroll: throttle(function () {
      const distanceToBottom = document.body.scrollHeight - (window.innerHeight + window.scrollY)

      if (distanceToBottom < 600) {
        this.fetchWorkOrders()
      }
    }, 100),
  },
}
</script>

<style lang="scss">
  .load-more {
    min-width: 20rem;
  }
</style>
