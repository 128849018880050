<template>
  <v-dialog v-model="needRefresh" max-width="500">
    <template v-slot:default>
      <v-card title="Er is een update beschikbaar">
        <v-card-text>
          Een nieuwe versie van de applicatie is beschikbaar. Wilt u nu updaten?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="updateServiceWorker">
            Update en herlaad
          </v-btn>
          <!-- <v-btn text @click="close">
            Later
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup>
import { useRegisterSW } from 'virtual:pwa-register/vue'

const refreshUpdateInterval = 1000 * 60 * 30 // 30 minutes

const {
  needRefresh,
  updateServiceWorker,
} = useRegisterSW({
  immediate: true,
  onRegisteredSW(_swUrl, r) {
    if (r && r.active) {
      setInterval(async () => {
        await r.update()
      }, refreshUpdateInterval)
    }
  },
})

async function close() {
  needRefresh.value = false
}
</script>
