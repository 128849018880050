import { useAuth } from "@eenvoud/eenvauth"
import axios from "axios"

export default {
  install () {
    useAuth().create({
      fetchData: {
        url: '/me',
        method: 'GET'
      },
      refreshData: {
        url: '/auth/refresh',
        method: 'POST'
      },
      loginData: {
        url: '/auth/login',
        method: 'POST'
      },
      logoutData: {
        url: '/auth/logout',
        method: 'POST'
      },
      apiLibrary: axios
    })
  }
}