<template>
  <v-card flat :class="condensed ? 'condensed' : ''">
    <v-card-title>
      <v-row class="justify-space-between flex-wrap">
        <div @click="goToAllLink()">
          <CardHeader
            :avatar-text="workOrdersTotal"
            :title="title"
            :description="description"
          />
        </div>

        <div>
          <v-row class="flex-wrap">
            <div class="show-historic-workorders">
              <v-switch v-model="showHistoricItems" color="info" label="Toon historisch" />
            </div>

            <div class="show-finished-workorders">
              <v-switch v-model="showFinishedItems" color="info" label="Toon afgerond" />
            </div>
          </v-row>
        </div>
      </v-row>
    </v-card-title>

    <v-card-text>
      <!-- List of work orders -->
      <template v-if="workOrdersList.length > 0">
        <v-row
          v-for="(workOrder, index) in workOrdersList"
          :key="index"
          class="align-center flex-wrap mt-0"
          :class="`work-order ${condensed ? 'condensed' : ''}`"
          @click="goToWorkOrder(workOrder.id)"
        >
          <v-col sm="5" md="5" class="px-0 py-1">
            {{ workOrder.project_name }} <br>
            {{ lpad(workOrder.id, 5) }}
          </v-col>
          <v-col sm="4" md="4" class="px-0 py-1">
            <h3>{{ workOrder.client?.name ?? '' }}</h3>
            <span>{{ workOrder.category }}</span>
          </v-col>
          <v-col sm="3" md="3" class="px-0 py-1">
            <template v-if="workOrder.location">
              {{ formatLocation(workOrder.location) }}<br>
            </template>
            {{ prettyDateFormat(workOrder.datetime_start) }}
          </v-col>
        </v-row>
        <v-alert v-if="!loading && workOrdersTotal === 0" type="warning" :value="true">
          {{ noWorkOrdersMessage ? noWorkOrdersMessage : 'Geen werkorders' }}
        </v-alert>
        <!-- Nothing to show -->

      </template>
      <v-row v-else class="flex-wrap">
        <v-col sm="10" offset-sm="1" class="no-workorders">
          Geen werkorders.
        </v-col>
      </v-row>
    </v-card-text>
    <p v-if="bottomLink && workOrdersTotal > 0" class="view-all">
      <router-link :to="bottomLink.route">
        {{ bottomLink.text }}
      </router-link>
    </p>
  </v-card>
</template>

<script>
import CardHeader from '@/components/Card/Header'
import text from '@/mixins/text'
import { randomMaterialColor } from '@/helpers'
import { lpad, prettyDateFormat } from '@/utils/filters'

export default {
  components: {
    CardHeader,
  },
  mixins: [text],
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    workOrdersList: {
      type: Array,
      required: true,
    },
    workOrdersTotal: {
      type: Number,
      default: 0,
    },
    bottomLink: {
      type: Object,
      required: false,
      default: undefined,
    },
    showCreateButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    noWorkOrdersMessage: {
      type: String,
      required: false,
      default: '',
    },
    loading: {
      type: Boolean,
      required: false,
    },
    condensed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      randomColors: [],
      lpad: lpad,
      prettyDateFormat: prettyDateFormat,
      showFinishedItems: false,
      showHistoricItems: false,
    }
  },
  watch: {
    'workOrdersList': function () {
      for (let i = 0; i < this.workOrdersList.length; i++) {
        this.randomColors.push(randomMaterialColor())
      }
    },

    showFinishedItems(show) {
      this.$emit('filterFinished', { show })
    },

    showHistoricItems(show) {
      this.$emit('filterHistoric', { show })
    },
  },
  methods: {
    goToWorkOrder(id) {
      this.$router.push({ name: 'WorkOrdersView', params: { id } })
    },
    determineColorFromStatus() {
      return 'green'
    },
    goToAllLink() {
      if (!this.bottomLink) return

      this.$router.push(this.bottomLink.route)
    },

    formatLocation(location) {
      const streetParts = [location.street]
      if (location.street_number) {
        streetParts.push(location.street_number)
      }
      const street = streetParts.join(' ')

      const addressParts = [street]
      if (location.city) {
        addressParts.push(location.city)
      }
      return addressParts.join(', ')
    },
  },
}
</script>

<style scoped lang="scss">
  .headline {
    text-transform: uppercase;
  }
  .card {
    .card__text {
      padding: 5px;
    }
  }
  .condensed {
    .work-order {
      padding: 6px 0;
      .work-order-info, .work-order-hours {
        line-height: 18px;
      }
      .work-order-status {
        span {
          font-size: 14px;
        }
      }
    }
  }
  .work-order {
    padding: 12px 0;
    border-bottom: 1px solid #ddd;
    &:last-of-type {
      border-bottom: none;
    }
    .work-order-avatar {
      text-align: center;
    }
    .work-order-info {
      padding-left: 10px;
      p {
        margin-bottom: 0;
      }
    }
    .work-order-status {
      span {
        font-size: 16px;
      }
    }
    .work-order-hours {
      .hour-start {
        font-size: 16px;
        font-weight: bold;
      }
      .day-start {
        color: #999;
      }
    }
  }
  .view-all {
    text-align: center;
    font-size: 16px;
    a {
      text-decoration: none;
    }
  }
  .btn--bottom.btn--absolute.btn--small {
    right: -10px;
  }
  .no-workorders {
    padding-left: 20px;
  }
  .show-finished-workorders {
    min-width: 160px;
  }
  .show-historic-workorders {
    min-width: 190px;
  }

  .v-card__title {
    padding: 16px 8px 0;
  }
  .v-card__text {
    padding: 8px;
  }
</style>
