export default {
  methods: {
    determineStatusColor(status) {
      /* eslint-disable no-unreachable */
      switch (status) {
        case 'DRAFT':
          return 'orange'
          break
        case 'PLANNED':
          return 'blue'
          break
        case 'ONGOING':
          return 'green'
          break
        case 'COMPLETED':
          return 'brown'
          break
        case 'CLOSED':
          return 'black'
          break
        case 'CANCELLED':
          return 'red'
          break
      }
      /* eslint-enable no-unreachable */
    },

    /**
     * Do not forget to set GOOGLE_MAPS_API_KEY: '"this projects api key here"' in app_local.js
     * to create one see: https://console.cloud.google.com/google/maps-apis/apis/static-maps-backend.googleapis.com/
     * @param {*} locationTo
     *
     * @returns css style with a static Google map containen from and to location as markers, set as the background image
     */
    getGoogleMapsImageStyle(locationTo) {
      let imageUrl
      if (!locationTo) {
        imageUrl = '/img/no-map.png'
      } else {
        let locationToInUrl = locationTo.location
        if(locationTo.latitude && locationTo.longitude) {
          locationToInUrl = `${locationTo.latitude},${locationTo.longitude}`
        }
        const baseUrl = `https://maps.googleapis.com/maps/api/staticmap`
        const size = '600x400'
        const zoom = '13'
        const apiKey = import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY
        const markerTo = `color:red|${locationToInUrl}`
        const markers = `markers=${markerTo}`
        const lang = `language=nl`
        imageUrl = `${baseUrl}?size=${size}&zoom=${zoom}&key=${apiKey}&${markers}&${lang}`
      }

      return `background:url('${imageUrl}'); background-repeat: no-repeat; background-position: top center; background-size: contain;`
    },
    // TODO: return activities from the text mixin
    getActivityFromOngoingStatus(status) {
      return status
    },
  },
}
