import moment from 'moment'

export const randomMaterialColor = function (color) {
  if (color === undefined) {
    const materialColors = [
      'red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal',
      'green', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'blue-grey',
    ]
    color = materialColors[Math.floor(Math.random() * materialColors.length)]
  }
  const materialShades = [
    { shade: 'lighten-5', text: 'black' },
    { shade: 'lighten-5', text: 'black' },
    { shade: 'lighten-5', text: 'black' },
    { shade: 'lighten-5', text: 'black' },
    { shade: 'lighten-5', text: 'black' },
    { shade: 'darken-1', text: 'white' },
    { shade: 'darken-2', text: 'white' },
    { shade: 'darken-3', text: 'white' },
    { shade: 'darken-4', text: 'white' },
  ]
  const shade = materialShades[Math.floor(Math.random() * materialShades.length)]
  return {
    color: color + ' ' + shade.shade,
    text: shade.text,
  }
}

/**
 * Converts a regular iso datetime string to an object with the date and time properties
 * as they are consumed by the Vuetify components (YYYY-MM-DD for date, HH:mm for time - no seconds)
 * @param String datetime datetime in iso format
 * @return { date: YYYY-MM-DD, time: HH:mm }
 */
export function datetimeSplit(datetime) {
  if (datetime != null && datetime.length === 19 && moment(datetime).isValid()) {
    datetime = moment(datetime, 'YYYY-MM-DD HH:mm:ss')
    return {
      date: datetime.format('YYYY-MM-DD'),
      time: datetime.format('HH:mm'),
    }
  } else {
    return {
      date: null,
      time: null,
    }
  }
}

/**
 * Appends seconds to datetime coming from datepicker
 * @param String date date in iso format
 * @param String time in hour:minutes format
 */
export function dateAndTimeToDatetime(date, time) {
  if (date && time) {
    return date + ' ' + time + ':00'
  } else {
    return null
  }
}

/**
 * Group locations based on their source
 */
export function groupLocations(original) {
  const locations = []
  const types = [
    // { key: 'vanAmerongen', name: 'Van Amerongen', flag_is_va_location: 1 },
    { key: 'client', name: 'Klant', flag_is_va_location: 0 },
  ]
  for (const type of types) {
    if (original[type.key].length > 0) {
      locations.push({
        disabled: true,
        id: type.key,
        location: type.name,
        flag_is_va_location: type.flag_is_va_location,
      })
    }
    for (const location of original[type.key]) {
      locations.push({
        id: location.id,
        location: location.location,
        flag_is_va_location: location.flag_is_va_location,
      })
    }
  }
  return locations
}
