import axios from 'axios'

export function fetchMonth(year, month) {
  return axios.get('/hours/month/' + year + '/' + month + '?own=true')
}

export function fetchWeek(year, week) {
  return axios.get('/hours/week/' + year + '/' + week + '?own=true')
}

export function createEntry(data) {
  return axios.post('/hours/entry', data)
}

export function removeEntry(id) {
  return axios.delete('/hours/entry/' + id)
}

export function fetchWeekStatus(year, weekNr) {
  return axios.get('/week-status/' + year + '/' + weekNr)
}
