import axios from 'axios'
import Notifications from '@kyvg/vue3-notification'
import auth from './utils/auth'
import i18n from './i18n'
import { LightTheme, defaults } from './components/theme/LighTheme'
import * as Sentry from "@sentry/vue";

import router from './router'
import store from './store/'

import api from './api/index'

import 'vuetify/dist/vuetify.min.css'
import './assets/app.scss'
import './utils/filters'

import { createApp } from 'vue'

import App from './App.vue'

// const OfflinePluginRuntime = require('offline-plugin/runtime')

// OfflinePluginRuntime.install({
//   onUpdateReady: () => OfflinePluginRuntime.applyUpdate(),
//   onUpdated: () => { window.swUpdate = true },
// })

// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labs from 'vuetify/labs/components'
import { registerSW } from 'virtual:pwa-register'

const vuetify = createVuetify({
  components,
  directives,
  ...labs,
  theme: {
    defaultTheme: 'LightTheme',
    themes: {
      LightTheme
    }
  },
  defaults
})

/**
 * Axios overwrite configs
 */
axios.defaults.baseURL = import.meta.env.VITE_APP_BASE_API_URL
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = import.meta.env.VITE_APP_BASE_API_URL
const JWTtoken = localStorage.getItem('auth_token_default') ? `Bearer ${localStorage.getItem('auth_token_default')?.replaceAll('"', '')}` : '';
axios.defaults.headers.common['Authorization'] = JWTtoken;

// add axios interceptor to handle unauthenticated error
api.initInterceptors()

const debug = process.env.NODE_ENV !== 'production'

// Plugins
import { registerPlugins } from '@/plugins'

const app = createApp(App)

Sentry.init({
  app,
  environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
  dsn: import.meta.env.VITE_APP_SENTRY_DNS,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/(?:[^.]\.)?vaf-infra\.nl/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, 
  // Session Replay
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

registerPlugins(app)

// This will make sure that the service worker is generated correctly
registerSW()

app.config.productionTip = false

app.use(store)
  .use(Notifications)
  .use(vuetify)
  .use(i18n)
  .use(auth)
  .mount('#app')
