<template>
  <div class="wrapper">
    <v-card v-if="!error" flat>
      <v-card-text>
        <p v-if="frozen && !loading">
          This week has been marked as frozen by an administrator. Changes are not possible.
        </p>
        <v-progress-circular v-if="loading" indeterminate color="primary" />
        <v-table v-else class="table table-week">
          <thead>
            <tr>
              <th>W{{ week }}</th>
              <th>begin</th>
              <th>eind</th>
              <th>omschrijving</th>
              <th>pauze</th>
              <th>uren</th>
              <th>piket</th>
              <th>overuren</th>
              <th>verlof</th>
            </tr>
          </thead>
          <tbody v-for="(weekDay, weekDayIndex) in weekDays" :key="weekDayIndex" @click="openWeekDialog(weekDayIndex)">
            <tr>
              <td
                :rowspan="days[weekDayIndex] ? days[weekDayIndex].length + 1 : 1"
                :colspan="!days[weekDayIndex] || days[weekDayIndex].length === 0 ? 9 : null"
                class="week-day"
              >
                {{ weekDay }}
              </td>
            </tr>
            <tr v-for="(entry, entryIndex) in days[weekDayIndex]" :key="entryIndex">
              <td>{{ entry.time_start ? entry.time_start : '' }}</td>
              <td>{{ entry.time_end ? entry.time_end : '' }}</td>
              <td>{{ entry.description }}</td>
              <td>
                {{ toFixed(entry.pause) }}
              </td>
              <td>
                {{ toFixed(entry.work_regular + entry.work_piket) }}
              </td>
              <td>
                <span v-if="entry.is_piket">
                  &#10003;
                </span>
              </td>
              <td>
                {{ toFixed(entry.work_overtime_total) }}
                <span v-if="entry.is_holiday"> (Feestdag)</span>
              </td>
              <td>
                {{ toFixed(entry.free + entry.sick + entry.special) }}
                <span v-if="entry.entry_type === 'SICK'">(ziek)</span>
                <span v-if="entry.entry_type === 'FREE'">(vrij)</span>
                <span v-if="entry.entry_type === 'SPECIAL'">(bij.)</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="week-day">
                TOT
              </td>
              <td colspan="3">
                {{ totals.daysWorked }} dagen gewerkt
              </td>
              <td />
              <td>{{ toFixed(totals.hoursRegular + totals.hoursPiket) }}</td>
              <td />
              <td>{{ toFixed(totals.hoursOvertime) }}</td>
              <td>
                {{ toFixed((totals.hoursFree + totals.hoursSpecial + totals.hoursSick)) }}
              </td>
            </tr>
          </tfoot>
        </v-table>
      </v-card-text>
    </v-card>

    <!-- Dialog -->
    <Dialog
      v-if="!frozen"
      :title="dialog.title"
      :visible="dialogVisible"
      :scrollable="true"
      content-class="dialog-hours"
      @update:visible="dialogVisible = $event"
      max-width="95%"
    >
      <template v-slot:content>
        <!-- New entry-->
        <h2>Blok toevoegen</h2>
        <v-card class="card-inner" flat>
          <v-card-text>
            <!-- Type -->
            <v-row>
              <v-col sm="3" class="label">
                Ik heb/was&nbsp;&nbsp;
              </v-col>
              <v-col sm="9">
                <v-btn-toggle v-model="dialog.newEntry.entry_type" mandatory>
                  <v-btn large flat value="WORK">
                    gewerkt
                  </v-btn>
                  <v-btn large flat value="FREE">
                    vrij
                  </v-btn>
                  <v-btn large flat value="SICK">
                    ziek
                  </v-btn>
                  <v-btn large flat value="SPECIAL">
                    bijzonder verlof
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <!-- Entire day? -->
            <v-row v-if="dialog.newEntry.entry_type !== 'WORK'">
              <v-col sm="3" class="label">
                Hele dag?
              </v-col>
              <v-col sm="9">
                <v-btn-toggle v-model="dialog.newEntry.allDay" mandatory>
                  <v-btn flat :value="true">
                    hele dag
                  </v-btn>
                  <v-btn flat :value="false">
                    deel van de dag
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <!-- Start and End -->
            <v-row v-if="dialog.newEntry.entry_type === 'WORK' || !dialog.newEntry.allDay">
              <v-col sm="5">
                <Timepicker
                  label="Start"
                  :model-value="dialog.newEntry.time_start"
                  @update:value-datetime="dialog.newEntry.time_start = $event"
                  :max-time="dialog.newEntry.time_end"
                />
              </v-col>
              <v-col sm="5" offset-sm="1">
                <Timepicker
                  label="Eind"
                  :model-value="dialog.newEntry.time_end"
                  @update:value-datetime="dialog.newEntry.time_end = $event"
                  :min-time="dialog.newEntry.time_start"
                />
              </v-col>
            </v-row>
            <!-- Pause, Holiday and Piket -->
            <v-row v-if="dialog.newEntry.entry_type === 'WORK'">
              <v-col sm="5">
                <v-select
                  v-model="dialog.newEntry.pause"
                  label="pause"
                  :items="pauseItems"
                  variant="underlined"
                />
              </v-col>
              <v-col sm="12">
                <v-checkbox
                  v-model="dialog.newEntry.flag_holiday"
                  label="Dit is / was een OFFICIELE FEESTDAG"
                />
              </v-col>
              <v-col sm="12">
                <v-checkbox
                  v-model="dialog.newEntry.flag_vda_piket"
                  label="Dit is / was een PIKET"
                />
              </v-col>
            </v-row>
            <!-- Description and submit -->
            <v-row>
              <v-col sm="8">
                <v-text-field
                  v-model="dialog.newEntry.description"
                  label="Omschrijving"
                  auto-grow
                  rows="2"
                  multi-line
                />
              </v-col>
              <v-col sm="4">
                <v-progress-circular v-if="dialog.loading" indeterminate color="primary" />
                <v-btn v-if="!dialog.loading" depressed color="primary" @click="createEntry()">
                  Toevoegen
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- Previous entries -->
        <v-card v-if="dialog.previousEntries.length !== 0" class="card-inner" flat>
          <v-card-text>
            <table class="table">
              <thead>
                <tr>
                  <th>begin</th>
                  <th>eind</th>
                  <th>omschrijving</th>
                  <th>pauze</th>
                  <th>uren</th>
                  <th>overuren</th>
                  <th>verlof</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(entry, index) in dialog.previousEntries" :key="index">
                  <td>{{ entry.time_start ? entry.time_start : '' }}</td>
                  <td>{{ entry.time_end ? entry.time_end : '' }}</td>
                  <td>{{ entry.description }}</td>
                  <td>{{ toFixed(entry.pause) }}</td>
                  <td>{{ toFixed(entry.work_regular + entry.work_piket) }}</td>
                  <td>
                    {{ toFixed(entry.work_overtime_total) }}
                    <span v-if="entry.is_holiday"> (Feestdag)</span>
                  </td>
                  <td>
                    {{ toFixed(entry.sick + entry.special + entry.free) }}
                    <span v-if="entry.entry_type === 'SICK'">(ziek)</span>
                    <span v-if="entry.entry_type === 'FREE'">(vrij)</span>
                    <span v-if="entry.entry_type === 'SPECIAL'">(bij.)</span>
                  </td>
                  <td>
                    <v-btn density="compact" icon @click="removeEntry(entry.id)">
                      <v-icon size="small">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </template>
    </Dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { fetchWeek, fetchWeekStatus, createEntry, removeEntry } from '@/api/hours'
import Say from '@/utils/Say'

import Timepicker from '@/components/UI/Timepicker'
import Dialog from '../Dialog'
import { toFixed } from '@/utils/filters'

const emptyNewEntry = {
  date_iso: null,
  entry_type: 'WORK',
  allDay: false,
  time_start: null,
  time_end: null,
  description: '',
  flag_holiday: false,
  flag_vda_piket: false,
  pause: 0,
}

export default {
  components: {
    Dialog,
    Timepicker,
  },
  props: {
    year: {
      type: String,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
    week: {
      type: [Number, String],
      required: false,
      default: '',
    },
  },
  data() {
    return {
      weekDays: [0, 1, 2, 3, 4, 5, 6].map(day => moment().isoWeekday(day + 1).format('dd')),
      pauseItems: [
        { value: 0, title: '0 minuten' },
        { value: 0.25, title: '15 minuten' },
        { value: 0.5, title: '30 minuten' },
        { value: 0.75, title: '45 minuten' },
        { value: 1, title: '1 uur' },
        { value: 1.25, title: '1 uur en 15 minuten' },
        { value: 1.5, title: '1 uur en 30 minuten' },
        { value: 1.75, title: '1 uur en 45 minuten' },
        { value: 2, title: '2 uur' },
      ],
      days: [],
      dates: [],
      totals: { },
      loading: false,
      error: null,
      dialogVisible: false,
      frozen: false,
      dialog: {
        title: 'date here',
        previousEntries: [],
        loading: false,
        dayIndex: null,
        newEntry: Object.assign({}, emptyNewEntry),
      },
      toFixed: toFixed
    }
  },
  watch: {
    'week': function () {
      this.getEntries()
    },
  },
  mounted() {
    this.getEntries()
  },
  methods: {
    getEntries() {
      this.loading = true
      return fetchWeekStatus(this.year, this.week)
        .then(res => {
          this.frozen = !!res.data.frozen
          return fetchWeek(this.year, this.week)
        })
        .then(res => {
          this.days = Object.values(res.data.days)
          this.dates = Object.keys(res.data.days)
          this.totals = {
            daysWorked: Object.values(res.data.days).reduce((total, day) => {
              let dayWorked = false
              for (const entry of day) {
                if (entry.entry_type === 'WORK') {
                  dayWorked = true
                }
              }
              return parseInt(total + (dayWorked ? 1 : 0))
            }, 0),
            hoursRegular: res.data.work_regular,
            hoursPiket: res.data.work_piket,
            hoursOvertime: res.data.work_overtime_total,
            hoursSpecial: res.data.special,
            hoursSick: res.data.sick,
            hoursFree: res.data.free,
          }
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.error = error.response
          Say('error', error.response.data)
        })
    },
    openWeekDialog(dayIndex) {
      // check if day belongs to this month
      const strMonth = this.dates[dayIndex].substring(5, 7)
      if (strMonth !== this.month) {
        return
      }
      // The dialog is conditionally rendered anyway
      if (!this.frozen) {
        this.clearNewEntry()
        this.dialogVisible = true
        this.dialog.title = moment(this.dates[dayIndex]).format('dddd D MMMM YYYY')
        this.dialog.previousEntries = this.days[dayIndex]
        this.dialog.newEntry.date_iso = this.dates[dayIndex]
        this.dialog.dayIndex = dayIndex
      }
    },
    clearNewEntry() {
      this.dialog.newEntry = Object.assign({}, emptyNewEntry)
    },
    removeEntry(id) {
      const confirmAction = confirm('Are you sure you want to remove this entry? This is irreversible.')
      if (confirmAction) {
        removeEntry(id)
          .then(this.getEntries)
          .then(() => {
            this.openWeekDialog(this.dialog.dayIndex)
            Say('success', 'Entry removed.')

            const weekHours = this.days.map(day => day.reduce((acc, cur) => acc + cur.work_total, 0))
            this.$emit('updateWeek', this.week, weekHours)
          })
          .catch(err => {
            Say('error', err.response.data)
          })
      }
    },
    createEntry() {
      this.dialog.loading = true
      const entry = {
        date_iso: this.dialog.newEntry.date_iso,
        entry_type: this.dialog.newEntry.entry_type,
        time_start: this.dialog.newEntry.allDay ? null : this.dialog.newEntry.time_start,
        time_end: this.dialog.newEntry.allDay ? null : this.dialog.newEntry.time_end,
        flag_holiday: this.dialog.newEntry.flag_holiday,
        flag_vda_piket: this.dialog.newEntry.flag_vda_piket,
        description: this.dialog.newEntry.description,
        pause: this.dialog.newEntry.pause,
      }
      createEntry(entry)
        .then(this.getEntries)
        .then(() => {
          Say('success', 'Success!')
          // Add the new entry to the daily data structure and then reinstantiate the dialog
          this.openWeekDialog(this.dialog.dayIndex)
          this.dialog.loading = false

          const weekHours = this.days.map(day => day.reduce((acc, cur) => acc + cur.work_total, 0))
          this.$emit('updateWeek', this.week, weekHours)
        })
        .catch(err => {
          Say('error', err.response.data)
          this.dialog.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0;
  margin: 0;
}
.table {
  &.table-week {
    thead {
      tr {
        th {
          &:first-child {
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
    }
  }
  thead {
    tr {
      height: 32px;
      th {
        text-align: left;
        padding: 0 6px;
        height: 32px;
      }
    }
  }
  tbody, tfoot {
    tr {
      td {
        border-bottom: 1px solid #ddd;
        padding: 0 6px;
        height: 32px;
        text-align: left;
        &.week-day {
          color: #999;
        }
      }
    }
  }
}
</style>
