<template>
  <v-card flat>
    <v-card-title>
      <CardHeader
        :avatar-color="avatarColor"
        :avatar-text="avatarText"
        :description="description"
        :actions="actions"
        :button="headerButton"
        :title="title"
      />
    </v-card-title>
    <v-card-text v-if="!hideContent">
      <v-card flat>
        <v-card-text :class="getClass()">
          <slot name="content" />
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import CardHeader from './Header'

export default {
  components: {
    CardHeader,
  },
  props: {
    avatarColor: {
      type: String,
      required: false,
      default: '',
    },
    avatarText: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    actions: {
      type: Array,
      required: false,
      default: undefined,
    },
    contentClass: {
      type: String,
      required: false,
      default: '',
    },
    noPadding: {
      type: Boolean,
      required: false,
      default: false,
    },
    headerButton: {
      type: Object,
      required: false,
      default: undefined,
    },
    hideContent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    getClass() {
      const classes = []
      if (this.contentClass) {
        classes.push(this.contentClass)
      }
      if (this.noPadding) {
        classes.push('no-padding')
      }
      return classes.join(' ')
    },
  },
}
</script>

<style scoped lang="scss">
.no-padding {
  padding: 0;
}
</style>
