export const LightTheme = {
    light: {
        background: '#f5f5f5',
    },
    colors: {
        primary: '#1976d2',
        secondary: '#424242'
    }
}

export const defaults = {
    VTextField: {
        variant: 'underlined'
    },
}

export default {
    LightTheme,
    defaults
}