<template>
  <v-container fluid>
    <v-row class="flex-wrap" xs="12">
      <v-tabs
        v-model="tabIndex"
        bg-color="blue-lighten-1"
        class="text-grey-darken-3"
        selected-class="text-black"
        hide-slider
      >
        <v-tab ripple value="Zoeken">
          Zoeken
        </v-tab>
        <v-tab ripple value="Mijn werkorders">
          Mijn werkorders
        </v-tab>
        <v-tab ripple value="Alle werkorders">
          Alle werkorders
        </v-tab>
      </v-tabs>
    </v-row>

    <v-window class="mt-8" v-model="tabIndex">
      <v-window-item value="Zoeken" >
        <SearchBox />
      </v-window-item>

      <v-window-item value="Mijn werkorders" >
        <!-- My workorders -->
        <WorkOrdersBox
          :title="text.workOrders.mine.title"
          :description="text.workOrders.mine.description"
          :bottom-link="{ text: 'Geef al mijn werkorders weer', route: { name: 'WorkOrdersList', params: { source: 'mine' } } }"
          :work-orders-list="myWorkOrdersList"
          :work-orders-total="myWorkOrdersTotal"
          :loading="loading.myWorkOrders"
          :show-create-button="true"

          @filterFinished="handleFilterFinishedMine"
          @filterHistoric="handleFilterHistoricMine"
        />
      </v-window-item>

      <v-window-item value="Alle werkorders" >
        <!-- All workorders -->
        <WorkOrdersBox
          :title="text.workOrders.all.title"
          :description="text.workOrders.all.description"
          :bottom-link="{ text: 'Geef alle werkorders weer', route: { name: 'WorkOrdersList', params: { source: 'all' } } }"
          :work-orders-list="allWorkOrdersList"
          :work-orders-total="allWorkOrdersTotal"
          :loading="loading.allWorkOrders"
          :condensed="true"

          @filterFinished="handleFilterFinishedAll"
          @filterHistoric="handleFilterHistoricAll"
        />
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
import text from '@/mixins/text'
import WorkOrdersBox from './ListBox'
import SearchBox from './SearchBox'
import useBranchSelector from '@/composable/useBranchSelector'
import SayError from '@/utils/SayError'

export default {
  components: {
    WorkOrdersBox,
    SearchBox,
  },
  mixins: [text],
  data() {
    return {
      loading: {
        myWorkOrders: false,
        allWorkOrders: false,
      },
      showFinishedMine: false,
      showFinishedAll: false,
      showHistoricMine: true,
      showHistoricAll: true,
      branchSelected: useBranchSelector().branchSelected,
      tabIndex: 0,
    }
  },
  computed: {
    pageTitle() {
      return this.$store.getters.appGetPageTitle
    },
    myWorkOrdersList() {
      return this.$store.getters.workOrdersMineList
    },
    myWorkOrdersTotal() {
      return this.$store.getters.workOrdersMineTotal
    },
    allWorkOrdersList() {
      return this.$store.getters.workOrdersAllList
    },
    allWorkOrdersTotal() {
      return this.$store.getters.workOrdersAllTotal
    },
  },
  mounted() {
    this.$store.commit('app.setPageTitle', 'Werkorders')
    // Mine
    this.fetchMineWorkOrders()
    // All
    this.fetchAllWorkOrders()
  },
  methods: {
    fetchMineWorkOrders() {
      const params = {
        rowsPerPage: 10,
        sortBy: 'datetime_start',
        descending: 'false',
      }
      if (!this.showFinishedMine) {
        params.status = 'DRAFT,PLANNED,ONGOING'
      }
      if (this.showHistoricMine) {
        params.showHistoric = true
      }

      this.loading.myWorkOrders = true
      this.$store.dispatch('workOrders.fetchMine', params)
      .then(function () {
        this.loading.myWorkOrders = false
      }.bind(this))
      .catch( error => {
        SayError(error)
        this.loading.allWorkOrders = false
      })
    },

    fetchAllWorkOrders() {
      const params = {
        rowsPerPage: 10,
        sortBy: 'datetime_start',
        descending: 'false',
      }
      if (!this.showFinishedAll) {
        params.status = 'DRAFT,PLANNED,ONGOING'
      }
      if (this.showHistoricAll) {
        params.showHistoric = true
      }

      this.loading.allWorkOrders = true

      this.$store.dispatch('workOrders.fetchAll', params)
      .then(function () {
        this.loading.allWorkOrders = false
      }.bind(this))
      .catch( error => {
        SayError(error)
        this.loading.allWorkOrders = false
      })
    },

    handleFilterFinishedMine({ show }) {
      this.showFinishedMine = show
      this.fetchMineWorkOrders()
    },

    handleFilterFinishedAll({ show }) {
      this.showFinishedAll = show
      this.fetchAllWorkOrders()
    },

    handleFilterHistoricMine({ show }) {
      this.showHistoricMine = show
      this.fetchMineWorkOrders()
    },

    handleFilterHistoricAll({ show }) {
      this.showHistoricAll = show
      this.fetchAllWorkOrders()
    },
  },
  watch: {
    branchSelected: {
      handler: function () {
        this.fetchAllWorkOrders()
        this.fetchMineWorkOrders()
      },
      deep: true,
    },
  }
}
</script>
