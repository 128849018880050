<template>
  <TitleBar :workOrder="workOrder"/>
  <template v-if="workOrder.status.name === 'APPROVED'">
    Werkorder is goedgekeurd en kan niet meer worden aangepast.
  </template>
  <template v-else>
  <template 
    v-if="
    !workOrder.is_for_equipment_movement ||
    ( workOrder.is_for_equipment_movement && 
    ( workOrder.category === 'Materiaal ophalen bij vestiging' || workOrder.category === 'Materiaal ophalen bij verhuur leverancier' )
    )
    ">
    <v-col sm="12">
      <div class="equipment">
        <h2 class="subtitle h-25px mt-0 mb-8">
          <template v-if="!workOrder.is_for_equipment_movement">
            Materiaal ophalen van project locatie(s)
          </template>
          <template v-else>
            <template v-if="workOrder.category === 'Materiaal ophalen bij vestiging'">
              Materiaal ophalen bij vestiging
            </template>
            <template v-if="workOrder.category === 'Materiaal ophalen bij verhuur leverancier'">
              Materiaal ophalen bij verhuur leverancier
            </template>
          </template>
        </h2>

        <template v-if="loading">
          Laden...
        </template>
        <template v-else>

          <template v-if="hasEquipment">
            <p>
              Haal je meer materialen op dan in onderstaand formulier ingevuld kan worden?<br>
              Neem dan contact op met de verantwoordelijke bij Van Amerongen of voeg een notitie toe aan deze werkorder.
            </p>
            <EquipmentTable :equipment="equipmentGroupedByLocation" :asForm="true" :workOrder="workOrder"/>
          </template>

          <template v-else>
            <p class="pb-7">
              Er is volgens het systeem (nog) geen materiaal aanwezig.
            </p>
            <p>
              Klopt dit niet?<br>
              Neem dan contact op met de verantwoordelijke bij Van Amerongen of voeg een notitie toe aan deze werkorder.
            </p>
          </template>

      </template>

      </div>
    </v-col>
  </template>
  <template v-else>
    Deze werkorder is niet bedoeld voor het ophalen van materiaal.
  </template>
  </template>

</template>

<script>
import { fetchJobEquipmentLocations } from '@/api/equipment'
import EquipmentTable from '@/components/UI/EquipmentTable'
import TitleBar from '@/components/WorkOrders/TitleBar'

export default {
  components: {
    EquipmentTable,
    TitleBar,
  },

  props: {
    workOrder: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      equipment: [],
      loading: true,
    }
  },

  computed: {
    equipmentGroupedByLocation() {
      if (!this.equipment || !this.equipment.data) {
        return {};
      }

      const groupedData = {};

      this.equipment.data.forEach(item => {
        const locationId = item.location.id;

        if (!groupedData[locationId]) {
            groupedData[locationId] = [];
        }
        if(item.collected_by_work_order_id === null) {
          groupedData[locationId].push(item);
        }
      });

      // remove empty locations
      Object.keys(groupedData).forEach(key => {
          if (groupedData[key].length === 0) {
              delete groupedData[key];
          }
      });

      return groupedData;
    },
    hasEquipment() {
      return Object.keys(this.equipmentGroupedByLocation).length > 0
    },
  },

  created() {
    this.fetchMaterialList()
  },

  methods: {
    getLocationAddress(location) {
      return location ? location.location : ''
    },

    async fetchMaterialList() {
      try {
        const locationId = this.workOrder.is_for_equipment_movement ? this.workOrder.location.id : null
        const materialListResponse = await fetchJobEquipmentLocations(this.workOrder.job_id, 1, locationId)

        materialListResponse.data.data = materialListResponse.data.data.filter(item => item.collected_at === null)

        this.equipment = materialListResponse.data
      } catch (error) {
        alert('Failed to fetch equipment list: ', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
